import axios from 'axios'
import Const from './const'

console.log('mode > ', process.env.NODE_ENV); // "development", "production" or "test"

// if(process.env.NODE_ENV == 'production') console.log = () => {}

// const log = (...args) => {
//     process.env.NODE_ENV == 'development' ? console.log(...args) : {};
// }

if (!String.prototype.format) {
    String.prototype.format = function(props) {
        return this.replace(/{(\w+)}/g, function(match, key) 
            { 
                return typeof props[key] != 'undefined'
                    ? props[key]
                    : ''
                ;
            }
        );
    };
}

const notifyEvent = (name, param) => {
    window.lastController().notifyEvent(name, param)
}

const registEventHandler = (obj) => {
    window.lastController().componentMounted(obj)
}

const popupModal = (param) => {
    /*
    const param = {
        header,
        body,
        callback,
        cancel : boolean // 취소버튼 제공여부
    }
    */

    const obj = {
        name: 'ModalDialog',
        param
      }
      popUp(obj);
}

const showImage = (url) =>{
    return window.lastController().showImage(url);
}

const home = () => {
    console.log('home')
    return window.lastController().home();
}

const goHome = () => {
    window.lastController().goHome();
}

const back = () =>{
    window.lastController().back();
}

const popUp = (obj) => {
    // const obj = {
    //     name: 'LoginForm',
    //     // name: 'MyView',
    //     param,
    // }

    window.lastController().popUp(obj);
}

const removeLastComp = () => {
    window.lastController().removeLastComp();
}

const popupLogin = (param = {}) => {    
    console.log('popupLogin');
    const obj = {
        name: Const.component.LoginForm,
        param,
    }
    window.lastController().popUp(obj);                
}

const closeAllPop = () => {
    window.lastController().closeAllPop();
}

const clone = (obj) => {
    const json = JSON.stringify(obj);
    return JSON.parse(json)
}

const toast = (msg) => {
    window.lastController().toast(msg)
}

const push  = (obj) => {
    const controller = window.lastController()
    controller.push(obj)                  
}

const printTimeString = (t) => {
    if(!t){
        return '';
    }
    // t : yyyy.mm.dd hh:mm 
    // console.log('t', t);
    let today = new Date();   

    let year = today.getFullYear(); // 시
    let month = today.getMonth() + 1; // 월
    let day = today.getDate(); // 월

    // console.log(today, year, month, day);

    const arr = t.split(' ');
    // console.log(arr)

    if(arr.length == 0) return '';

    const pre = arr[0].split('-');
    // console.log(pre);

    // 해가 다르면
    if(pre[0] < year) return pre[0];

    // 같은 해인데 월이 다르면
    if(pre[1] < month) return `${pre[0]}-${pre[1]}`;

    // 년월이 같고 일이 이전이면 
    if(pre[2] < day) return `${pre[1]}-${pre[2]}`;

    // 같은 날짜면
    const arr_time = arr[1].split(':')
    return `${arr_time[0]}:${arr_time[1]}`;

    // let hours = today.getHours(); // 시
    // let minutes = today.getMinutes();  // 분
    // let seconds = today.getSeconds();  // 초
    // let milliseconds = today.getMilliseconds(); // 밀리초
}

const getLocation = (f) => {
    console.log('getLocation');
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(f);
    } else { 
      alert('위치 가져오기 실패');
    }
}

const saveLocation = (obj, cb) => {
    const str = JSON.stringify(obj);
    localStorage.setItem('position', str);

    if(cb) cb();    
}

const loadLocation = () => {
    const temp = localStorage.getItem('position')
    if(temp) return JSON.parse(temp)
}

const post = (url, body, proc) => {
    axios.post(url, body )
    .then(checkResponse(proc))
    .catch(error => {
        console.log(error);
    });                    
}

const api_post = (url, params, cb, onError) => {
    // cb : 결과 data 전달
    // modal : 에러메시지 처리에 대해 
    console.log('api_post.params', url, params);
    axios.defaults.withCredentials = true;


    const post = (url, params, cb) => {
        console.log('post')
        axios.post(url, params)
        .then((res) => {    
            const {error, msg} = res.data;
    
            if(error == 0)
            {                
                cb(res.data)
            }
            else if(error > 0 && onError)
            {
                onError(msg, error);
                return;
            }
        });        
    }

    axios.post(url, params)
    .then((res) => {
        console.log('res.data', res.data);
        const {error, msg} = res.data;

        if(error == 100)
        {
            // tryLogin(
            //     () => { post(url, params, cb) }
            // );

            const onSuccess = () => { post(url, params, cb) };
            const onFailed = popupLogin;
            const onEmpty = onFailed;
            loginAuto({ onSuccess, onFailed, onEmpty });
            return;
        }else if(error > 0)
        {
            if(onError) onError(msg, error);
            else {
                const param = {
                    header: '안내',
                    body : msg,
                    // callback
                }

                const obj = {
                    name: 'ModalDialog',
                    param
                }
                popUp(obj);                
            }
            return;
        }

        // error == 0
        cb(res.data)
    });
}

// 세션이 없는 경우 로그인을 시도해서 가져온다.
const fetch = (url, cb, onError) => {
    axios.defaults.withCredentials = true;

    const p = (notLogin) => {
        axios.get(url).then(
            (res) => {
                console.log('res.data', url, res.data);

                const {error, msg} = res.data;
                if(error == 0){
                    cb(res)
                }
                if(error == 100) // notLogin 이 지정 되지 않으면 로그인 창
                {
                    if(notLogin)
                        notLogin();
                    else
                    {
                        cb(res);
                    }            
                }else if(error > 0 && onError)
                {
                    if(onError) 
                        onError(msg);
                    else
                    {
                        const header = '오류'
                        const param = {
                            header,
                            body: msg,
                        }
                        popupModal(param);        
                    }
                }
            }
        )
    }

    p( () => {
        tryLogin(p);
    })
}

const api_get = (url, cb, onError, notLogin) => {
    axios.defaults.withCredentials = true;
    axios.get(url)
    .then((res) => {
        console.log('res.data', url, res.data);

        const {error, msg} = res.data;
        if(error == 0){
            cb(res)
        }
        if(error == 100) // notLogin 이 지정 되지 않으면 로그인 창
        {
            if(notLogin)
                notLogin();
            else
            {
                cb(res);
            }            
        }else if(error > 0 && onError)
        {
            if(onError) 
                onError(error, msg);
            else
            {
                const header = '오류'
                const param = {
                    header,
                    body: msg,
                }
                popupModal(param);        
            }
        }
    });
}

const errorHandler = (error, notLogin, notLocation) => {
    switch (error) {
        case Const.err_code.not_login:
            if(notLogin) notLogin();
            else
                window.location = 'login.html';
          break;
        case Const.err_code.not_location:        
            if(notLocation) notLocation();
            else
                console.log('notLocation');
          break;              
        default:
          break;
    }
}

const timeFormat = (col) => {
    return `DATE_FORMAT(${col}, "%Y-%m-%d") ${col}`
}

const param = (name) => {
    name=(new RegExp('[?&]'+encodeURIComponent(name)+'=([^&]*)')).exec(location.search)
    if(name)
       return decodeURIComponent(name[1]);
}
  
const regiScrollBottom = (list_id, cb) => {
    const listElm = document.querySelector('#' + list_id);

    listElm.addEventListener('scroll', () => {
        // console.log('listElm.scrollTop', listElm.scrollTop);
        // console.log('listElm.clientHeight', listElm.clientHeight);
        // console.log('listElm.scrollHeight', listElm.scrollHeight);

        // listElm.scrollTop : 0에서 시작하고 스크롤다운을 할 수록 값이 증가
        // listElm.clientHeight : 영역의 고정 높이
        // listElm.scrollHeight : 컨텐츠의 총 길이

        if(listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {          
            cb();
        }      
    });      
}

const checkMe = (cb) => {
    const url = Const._api + 'me'
    api_get(url, cb);
}

const saveAccount = (uid, pw) => {
    localStorage.setItem('uid', uid);
    localStorage.setItem('pw', pw);
}

const login = (uid, pw, cb, board_idx=0, key) => {
    console.log('login')
    const params = {uid,pw, board_idx, key};
    api_post(Const._api + "login" , params, 
        (data) => {
            console.log('login.data', data);
            var {error, msg} = data;
            if(error > 0){
                saveAccount('', '')
                cb(false, msg);
            }
            else  // login
            {
                const {user, persona} = data;
                saveAccount(uid, pw)
                cb(true, user, persona)
            }          
        }
        ,(msg) => {
            localStorage.setItem('uid', '');
            localStorage.setItem('pw', '');
            localStorage.setItem('key', '');

            const param = {
                header: '로그인 실패',
                body : msg,
                // callback
            }


            const obj = {
                name: 'ModalDialog',
                param
            }
            popUp(obj);                


            cb(false, msg);
        }
    );     
}

const clearStorage = () => {
    localStorage.setItem('uid', '');
    localStorage.setItem('pw', ''); 
    localStorage.setItem('key', ''); 
    localStorage.setItem('position', '');
}

// 로컬에 있다면 로그인을 시도한다.
// onSuccess, 성공
// board_idx 페르소나 정보를 얻기 위해
// onEmpty, 
// onFailed, 실패
const loginAuto = (params) => {
    console.log('loginAuto');
    const {onSuccess, board_idx, onEmpty, onFailed} = params;

    var uid = localStorage.getItem('uid');
    var pw = localStorage.getItem('pw');
    var key = localStorage.getItem('key');

    console.log('key', key)

    if( key || (uid != null && uid.length > 0))
    {         
        console.log('로컬정보로 로그인 시도')         
        login(uid, pw, 
            (res, user, persona) => {
                console.log('res', res);
                if(res) // 성공
                {
                    if(onSuccess)
                        onSuccess(user, persona);
                }
                else if(onFailed){
                    onFailed();
                }
            }
            , board_idx
            , key
        );
    }
    else{
        console.log('로컬에 정보 없슴')

        if(onEmpty){
            onEmpty();  // 로컬에 계정정보 없다.
        }
    } 
}

// 로컬 정보로 로그인을 시도한다.
// 성공하면 토스트를 띄우고
// 실패하면 로그인 창을 띄운다.
// 비로그인시 로그인이 필요한 UI를 제공하지 않는게 맞지만
// 시간경과로 프론트와 백의 로그인상태가 맞지 않은 경우
// 즉, 서버의 세션이 무효화 된 경우 자동로그인 하기 위한 것이다.
const tryLogin = (cb) => {
    console.log('tryLogin');
    if(cb){
        checkSession(cb)
    }
    else{ // 콜백을 정의하지 않은 경우 토스트 성공 메시지를 보낸다.
        checkSession((isLogin) => {
            if(isLogin){
            //   this.$refs.snackbar.show('재로그인 되었습니다. 다시 시도하세요.');
            toast('재로그인 되었습니다. 다시 시도하세요.');
            //   const param = {
            //       header : '재로그인',
            //       body: '오랫동안 사용하지 않아\n재로그인 되었습니다.',
            //   }
            //   popupModal(param);                  
            }else{
              popupLogin();       
            }
          }
        )
    }
}

// 세션여부를 체크하고 
// 비로그인인 경우 저장된 계정으로 로그인을 시도하고
// 결과를 전달한다.
// 사용측면에서는 mounted에서 한번 호출해주면 된다.
// cb(result, user, location)
// - result : boolean
// - user
// - location
const checkSession = (cb) => {
    checkMe(
        (res) => {
            const {data} = res;
            console.log('checkMe', data);
            const {error, user} = data;
            if(error === 0 && user && user.idx){
                console.log('로그인상태')
                cb(true, user);
            }
            else{
                // 로그인 시도
                console.log('비로그인상태')

                // const onSuccess = (user) => {
                //     cb(true, user);
                // }
                // const onFailed = () => {
                //     cb(false);
                // }
                // const onEmpty = onFailed;
                
                // const params = {onSuccess, onFailed, onEmpty}
                // loginAuto(params);

                cb(false);
            }
        }
    )
}

const copyToClipboard = (text) => {
    var textarea = document.createElement('textarea');
    textarea.value = text;
    document.body.appendChild(textarea);
    textarea.select();
    textarea.setSelectionRange(0, 9999);    // For IOS
    document.execCommand('copy');
    document.body.removeChild(textarea);             
}

const showMenuEx = (obj) => {
    window.lastController().showMenuEx(obj);
}


const showMenu = (arr) => {
    window.lastController().showMenu(arr);
}

const elapsedTime = (date) => {
    // const start = new Date(date);
    if(!date) return;

    const start = new Date(date.replace(/-/g, "/"))
    const end = new Date(); // 현재 날짜

    const diff = (end - start); // 경과 시간

    const times = [
        {time: "분", milliSeconds: 1000 * 60},
        {time: "시간", milliSeconds: 1000 * 60 * 60},
        {time: "일", milliSeconds: 1000 * 60 * 60 * 24},
        {time: "개월", milliSeconds: 1000 * 60 * 60 * 24 * 30},
        {time: "년", milliSeconds: 1000 * 60 * 60 * 24 * 365},
    ].reverse();

    // 년 단위부터 알맞는 단위 찾기
    for (const value of times) {
        const betweenTime = Math.floor(diff / value.milliSeconds);
            
        // 큰 단위는 0보다 작은 소수 단위 나옴
        if (betweenTime > 0) {
            return `${betweenTime}${value.time} 전`;
        }
    }

    // 모든 단위가 맞지 않을 시
    return "방금 전";
} 
// console.log('elapsedTime', elapsedTime('2022-11-16 09:00:00'));

// 특수문자 없애기
const removeSChar = (str) => {  
    // eslint-disable-next-line
    var reg = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/gi
    //특수문자 검증
    if(reg.test(str)){
        //특수문자 제거후 리턴
        return str.replace(reg, "");    
    } else {
        //특수문자가 없으므로 본래 문자 리턴
        return str;
    }  
}

const blockPersona = (idx, cb = () => {}) => {
    const params = {
        idx,
    };
    const url = Const._api + "block/add";
    api_post(
        url, 
        params, 
        (data) => {
            const {error, idx, msg} = data;
            msg
            if(error == 0){
                toast('차단되었습니다.')
                cb(idx)
            }
            else if(error == 100)
            {
                tryLogin();
            }
            else{
                // 
            }
        }
    )    
}

const unBlockPersona = (idx, cb = () => {}) => {
    const params = {
      idx
    };
    const url = Const._api + "block/remove";
    api_post(
      url, 
      params, 
      (data) => {
        const {error, msg, affected} = data;
        affected
        msg
        if(error == 0){
          toast('차단 해제되었습니다.')
          cb();
        }
        else if(error == 100)
        {
          tryLogin();
        }
        else{
          // console.log('error', msg)
        }
      }
    )                
}

const handleLink = (src) => {
    let stripped = src.replace(/</g, '&lt');
    stripped = stripped.replace(/>/g,"&gt;"); 

    let result = '';
    const lines = stripped.split('\n');
    lines.forEach(line => {
    // < with &lt; and > with &gt;
    let after = '';
    const array = line.split(' ');
    array.forEach(element => {
        if( element.startsWith('http://') || element.startsWith('https://'))
        {
        const temp = `<a href='${element}' target='_blank'>${element}</a>`

        after += ' ' + temp;
        }
        else{
        after += ' ' + element
        }
    });

    result += after + '\n';
    });
    return result;
}

const showReportType = (cb)=>{
    const menus = [];
    for (let key in Const.reportType) {
      menus.push({
        title : Const.reportType[key],
        cb: () => { cb(key)},
      });
    }

    const obj = {
      title: '신고사유를 선택해주세요',
      menus
    }
    showMenuEx(obj);                
}

const checkPw = (pw) => {
    // 비밀번호 자리 8~20자, 영문, 숫자, 특수문자 포함, 공백제외

    var num = pw.search(/[0-9]/g);
    var eng = pw.search(/[a-z]/ig);
    var spe = pw.search(/[`~!@@#$%^&*|₩₩₩'₩";:₩/?]/gi);

    if(pw.length < 8 || pw.length > 20){

        // alert("8자리 ~ 20자리 이내로 입력해주세요.");
        return false;
    }
    else if(pw.search(/\s/) != -1){
        // alert("비밀번호는 공백 없이 입력해주세요.");
        return false;
    }else if(num < 0 || eng < 0 || spe < 0 ){
        // alert("영문,숫자, 특수문자를 혼합하여 입력해주세요.");
        return false;
    }else {
        console.log("통과"); 
        return true;
    }
}

const shrinkSpace = (text) => {
    // text = text.trim();
    let old = text.length;
    let removed;
    do{
        text = text.replace(/\s\s+/g, " ");
        removed = text.length;
        if(old > removed) old = removed;
        else break;
    }while(old > removed)

    return text;
}

const closePopup = () => {
    window.lastController().closePopup();
}

const checkResponse = (func) => (
    (response) => {
        const {error} = response.data;
        if(error == 100){
            popupLogin()
            return;
        }

        func(response.data)
    }
)

const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
}

// console.log('shrinkSpace', shrinkSpace(' ')+'.')

const timeString = (date = null, formatString = null) => {
    // ex : `{mon}.{day} {hour}:{min}`
    let dt = new Date(date)

    var mon = `${(dt.getMonth()+1)}`.padStart(2, "0");
    var day = `${(dt.getDate())}`.padStart(2, "0");
    var year = dt.getFullYear();
    let hour = `${(dt.getHours())}`.padStart(2, "0"); // 시
    let min = `${(dt.getMinutes())}`.padStart(2, "0");  // 분
    let sec = `${(dt.getSeconds())}`.padStart(2, "0");  // 초

    const obj = {mon, day, year, hour, min, sec};

    const str = formatString ? formatString : `${year}-${mon}-${day} ${hour}:${min}:${sec}`

    return str.format(obj)
}

const popupShare = (idx, hash) => {
    const param = {
    url: `${window.origin}/ad/${idx}/${hash}`
    } ;

    popUp({
        name: Const.component.QrcodeVue,
        param
    })    
}

const popUpPostForm = () => {
    const param = {
        data: {
          idx: 0
        }
    }
    const obj = {
        name: Const.component.PostForm,
        param
    }
    popUp(obj);
}

const getLocalParent = () => {
    return localStorage.getItem('parent', '')
}

const setLocalParent = (parent) => {
    return localStorage.setItem('parent', parent)
}

const setEventIdx = (idx) => {
    return localStorage.setItem('event', idx)
}

const getLocalEvent = () => {
    return localStorage.getItem('event', '')
}

const moveSmooth = (startValue, endValue, updated, ended) => {
    // console.log('moveSmooth', startValue, endValue)

    // 현재 스크롤 위치
    // const startPosition = window.scrollY;
    const distance = endValue - startValue;

    // 애니메이션 진행 시간
    const duration = 400; // 밀리초 단위로 원하는 시간으로 수정

    let startTime;

    function scrollToSmoothly(timestamp) {
        if (!startTime) startTime = timestamp;

        const elapsed = timestamp - startTime;
        const progress = Math.min(elapsed / duration, 1);

        const easing = easeOutQuad(progress);
        const newPosition = startValue + distance * easing;

        // console.log('newPosition', newPosition)
        if(updated) updated(newPosition)

        if(newPosition == endValue) {
            if(ended) ended();
        }

        if (elapsed < duration) {
            requestAnimationFrame(scrollToSmoothly);
        }
    }

    // 가속도 함수 (easeOutQuad)
    function easeOutQuad(t) {
        return t * (2 - t);
    }

    // 스크롤 애니메이션 시작
    requestAnimationFrame(scrollToSmoothly);
}

const isDev = () => {
    return process.env.NODE_ENV == 'development'
}

export default {
    param,
    errorHandler,
    loginAuto,
    checkMe,
    timeFormat,
    regiScrollBottom,
    login,
    api_post,
    api_get,
    getLocation,
    clearStorage,
    checkSession,
    printTimeString,
    push,
    clone,
    popupLogin,
    registEventHandler,
    notifyEvent,
    popUp,
    popupModal,
    goHome,
    home,
    copyToClipboard,
    tryLogin,
    removeLastComp,
    back,
    toast,
    saveLocation,
    loadLocation,
    showMenu,
    showMenuEx,
    elapsedTime,
    removeSChar,
    showImage,
    blockPersona,
    unBlockPersona,
    handleLink,
    showReportType,
    fetch,
    shrinkSpace,
    closePopup,
    checkPw,
    checkResponse,
    validateEmail,
    timeString,
    popupShare,
    popUpPostForm,
    getLocalParent,
    setLocalParent,
    closeAllPop,
    moveSmooth,
    setEventIdx,
    getLocalEvent,
    post,
    isDev,
}