<template>
    <div id="myModal" class="modal ">
        <div class="modal-content max-width">
            <div class="modal-header">
                <div>{{param.header}}</div>
            </div>
            <div class="modal-body">
                <pre class="modal-text">{{param.body}}</pre>
            </div>
            <div @click="done" class="modal-footer">
                <div>확인</div>
            </div>
            <div v-if="param.cancel" @click="hide" class="modal-footer">
                <div style="color: gray">취소</div>
            </div>
        </div>
    </div>
</template>

<script>
// import common from '@/common';
export default {  
    props:{
      param: Object,
      close: Function,
    },
    mounted() {        
        console.log('param', this.param)
    },
    data: function () {
      return {  
      };
    },
    methods: {
        done(){
            this.close();
            if(this.param.callback){
                this.param.callback();
            }
        },
        hide(){
            this.close();
        }
    },      
}

</script>

<style scoped>
@import '@/assets/common';

/* The Modal (background) */
.modal {
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    position: relative;
    background-color: #fefefe;
    margin: auto;
    padding: 0;
    border: 1px solid #888;
    width: 80%;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: modal-animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: modal-animatetop;
    animation-duration: 0.4s
}

/* Add Animation */
@-webkit-keyframes modal-animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
}

@keyframes modal-animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
}

/* The Close Button */
.modal .close {
    /* color: white; */
    float: right;
    font-size: 16px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}

.modal-header {
    text-align: center;
    font-size: 16px;
}
.modal-body {
    padding: 2px 16px;
    text-align: center;
    font-size: 16px;
}

</style>

