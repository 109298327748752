<template>
    <div class="title-bar-container bar-background-color border-bottom " >
        <div class="left">
            <div v-show="left" class="tb-btn" @click="onclick('left')" v-html="left"></div>
        </div>
        
        <!-- 가운데 -->
        <div @click="onclick('title')" class="middle" v-html="title">
        </div>
        
        <div class="right">
            <div class="tb-btn" @click="onclick('right')" v-html="right"></div>
        </div>
    </div>
</template>

<script>
export default {  
    props:{
      data:Object,
    },
    mounted() {
        // console.log('data', this.data)
        this.title = this.data.title;

        this.left = this.data.left;

        if(this.data.left == null) this.left = '<i class="fa fa-chevron-left"></i>';

        this.right = this.data.right;
        if(!this.data.right) this.right = '<i class="fa fa-bars"></i>';
    },
    data: function () {
      return {  
        title: '',
        left:'',
        right:'',
      };
    },
    methods: {
      onclick(menu){
        this.$emit(menu, this.data)
      },
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.middle {
  display:flex; 
  align-items: center;  
  justify-content: center;
}

.title-bar-container {
    display: flex;
    justify-content: space-around;    
    position: sticky;
    top: 0; /* Position the navbar at the top of the page */  
}

.title-bar-container > div {
    width: 60%;
    margin: 10px;
    text-align: center;
    /* font-size: 30px; */
}

.title-bar-container .btn {
    display:flex; 
    align-items: center;  
    justify-content: center;
    /* background: yellow; */
    font-size: initial;    
}

.title-bar-container .right{
    width: 20%;
    display: flex;
    justify-content: flex-end;
}

.title-bar-container .left {
    width: 20%;
    display: flex;
    justify-content: flex-start;
}
</style>

