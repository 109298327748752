<template>
  <div style="background: white;">
    <div class="between margin-top10 padding10">
      <div />
      <button type="button" class="btn color-gray" @click="close()">닫기</button>
    </div>

    <div style="    
      margin: 30px auto;
      width: fit-content;
    ">
      <logo-view :size="30" />
    </div>

    <form class="login-container">
      <!-- 또는 -->
      <div class="center-text font-size12 color-darkgray margin-bottom10">간편가입 | 로그인</div>
      <div class="buttons margin-bottom10" id="sns">
        <img @click="onKakao" src="@/assets/img/kakao.png"/>
        <img @click="onNaver" src="@/assets/img/naver.png"/>
        <img @click="onTest" src="@/assets/img/google.png"/>        
        <!-- <img @click="onGoogle" class="google" src="@/assets/img/google.png"/> -->
      </div>
    </form>
  </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'
// import LogoImage from './common/LogoImage.vue';
import LogoView from './common/LogoView.vue';
// import JoinForm from './JoinForm.vue'

const Kakao = window.my.Kakao;
// console.log(Kakao.isInitialized()); // sdk초기화여부판단

export default { 
  components: {
    // LogoImage,
    LogoView
    // JoinForm,
  }, 
  props:{
    param:Object,
    close: Function,
  },
  mounted() {
    console.log("LoginForm.mounted", this.param)
  },
  data: function () {
    return {  
      //   name: value
      uid: '',
      pw: '',
      message:'',
      name: Const.name,
      // name: `<div class="" style="font-size:24px; font-weight: 900;">${Const.name}</div>`
    };
  },
  methods: {
    onTest(){
      // 기존에 회원으로 로그인한다...
      window.location = Const._api + "testlogin";
    },
    onGoogle(){
      console.log('onGoogle')
    },
    onNaver(){
      const url = Const._api + `naver/state`;
      common.api_get(
        url, 
        res => {
          console.log(res.data)

          const {state} = res.data;
          const client_id = 'V646yJIYJAjeWrBHgNJf';
          const redirectURI = encodeURI(process.env.NODE_ENV == 'production' ? `${Const._api}naver` : 'http://localhost:8001/api/naver');
          console.log('redirectURI', redirectURI)

          const api_url = 'https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=' + client_id + '&redirect_uri=' + redirectURI + '&state=' + state;            
          window.location = api_url;
          // console.log(api_url)
        }
      )
    },
    onKakao(){
      const redirectUri = process.env.NODE_ENV == 'production' ? `${Const._api}kakao` : 'http://localhost:8001/api/kakao';
      Kakao.Auth.authorize({
        redirectUri
      });        
    },
    onForgot(){
      common.popUp({
        name: Const.component.ForgotPassword,
        param: {}
      })        
    },
    onWidthDraw(){
      // 탈퇴
      // if (Kakao.Auth.getAccessToken()) {
      //   Kakao.API.request({
      //     url: '/v1/user/unlink',
      //     success: function (response) {
      //       console.log(response)
      //     },
      //     fail: function (error) {
      //       console.log(error)
      //     },
      //   })
      //   Kakao.Auth.setAccessToken(undefined)
      // }
    },
    onEnter(id){
      switch (id) {
        case 'id':
          this.$refs.pw.focus();
          break;
        case 'pw':
          this.login();
          break;        
        default:
          break;
      }
    },
    login: function(){
      const url= Const._api + "login";

      const params = {
        uid: this.uid,
        pw: this.pw,
      };

      common.api_post(url , params, (data) => {            
          console.log('login.data', data);
          var {error, msg} = data;
          if(error > 0){              
            // todo : temp의 경우 지우지 말고 유지해야 한다.
            localStorage.setItem('uid', '');
            localStorage.setItem('pw', '');

            this.message = msg;
            this.$emit('clicked', 'failed', msg);
          }
          else  // login            
            this.didLogin(data)
      });     
    },
    didLogin(data){
      console.log('didLogin', data);        
      localStorage.setItem('uid', this.uid);
      localStorage.setItem('pw', this.pw);

      // const {user, persona} = data;
      // this.$emit('clicked', 'success', {user, persona});        
      common.notifyEvent(Const.notify.login, data)

      this.close();

      // didLogin 이 있다면
      if(this.param && this.param.didLogin) this.param.didLogin();
    },
    show: function(){
      this.message = '';
      this.email = '';
      this.pw = '';

      // todo : 로컬스토리지에 있다면 바로 시도한다.            
    },
    onJoin(){
      // this.$emit('clicked', 'join', {});
      common.popUp({
        name: Const.component.JoinForm,
        param: {}
      })
    },
    testAccount(){
      // const url= Const._api + "temp_account_is_temp";

      // const params = {
      //   serail: 'xxxxxx' // todo: 봇이 아님을 확인하는 인증값을 담아야 한다.
      // };

      // common.api_post(url , params, (data) => {                        
      //     var {error, msg} = data;
      //     if(error > 0){
      //         localStorage.setItem('uid', '');
      //         localStorage.setItem('pw', '');

      //         this.message = msg;
      //         this.$emit('clicked', 'failed', msg);
      //     }
      //     else{
      //       // 임시 계정 완료
      //       console.log('생성 완료')
      //       const {uid, pw} = data;
      //       localStorage.setItem('uid', uid);
      //       localStorage.setItem('pw', pw);

      //       // 안내메시지 보여주고 확인을 받으면 시작
      //       const param = {
      //           header: '계정생성 완료',
      //           body: '임시 계정생성을 완료했습니다. 즐거운 어바우팅 되십시요.',
      //           callback: () => {
      //             this.uid = uid;
      //             this.pw = pw;
      //             this.login();
      //           }
      //       }
      //       common.popupModal(param);
      //     }
            
      // });   
    },     
    onTemp(){
      this.close();

      common.popUp({
        name: Const.component.JoinForm,
        param: {}
      })
    },
  },      
}

</script>

<style scoped>
@import '@/assets/common';

.buttons{
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.google {
  border: solid lightgray;
}

#sns > img {
  width: 270px;
  /* height: 40px; */
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
}
.container {
  display: flex; 
  flex-direction: column; 
  margin-left: auto; 
  margin-right: auto; 
  width: fit-content;
}

.join {
  display:flex; 
  margin-right: 0; 
  width: fit-content;
}

.open-button {
  /* background-color: #555; */
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  /* position: fixed; */
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.login-container {
    top:0;
    bottom: 0;
    /* right: 15px; */
    z-index: 9;
    max-width: 100%;
    padding: 10px 30px;
    height: 100vh;
    background-color: white;
}

.login-container .btn {
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    border-radius: 20px;
}
  
  /* Full-width input fields */
.login-container input[type=text], .login-container input[type=password] {
  width: 270px;
  height: 38px;

  margin-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
    /* padding: 15px; */
  /* padding-top: 15px; */
  /* padding-bottom: 15px; */
  /* margin: 5px 0 22px 0; */
  /* border: none; */
}
  
/* When the inputs get focus, do something */
.login-container input[type=text]:focus, .login-container input[type=password]:focus {
  /* background-color: #ddd; */
  outline: none;
}

input {
  border: 1px solid rgb(211, 211, 211);
  padding-left: 10px;
}

input::placeholder {
    /* opacity: .5; */
    color: rgb(128, 128, 128);
    /* padding-left: 10px; */
}

.warning {

}

.cherry {
    font-size: 20pt;
    font-weight: bold;    

    color: white;
    width: fit-content;
    padding: 5px 20px 8px;
    border-radius: 16px;
    margin: 0px 10px;
}

</style>

