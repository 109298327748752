<template>
    <modal-dialog-ex :param="param" :close="close" />
</template>

<script>
import ModalDialogEx from './ModalDialogEx.vue';
// import common from '@/common';
export default {
  components: { ModalDialogEx },  
    props:{
      param: Object,
      close: Function,
    },
    mounted() {        
        console.log('param', this.param)
    },
    data: function () {
      return {  
      };
    },
    methods: {
    },      
}

</script>

<style scoped>
@import '@/assets/common';



</style>

