<template>
    <div class="indicator" id="indicator">
        <div v-if="show_seconds"  class="seconds">{{lastSec}}</div>
        <div v-else class="next">밀어서 넘기기</div>
    </div>
</template>

<script>
// import common from '@/common'
// import constVal from '@/const'

export default {  
    props:{
      data:Object,
    },
    data() {
      return {        
        lastSec: '',
        passed: 0, // 지난 시간
        timer: null,
        show_seconds: true,
      };
    },
    mounted() {
    },
    unmounted(){
        this.stop();
    },
    methods: {
        stop(){
            clearTimeout(this.timer);
            this.passed = 0;
            this.updateSec();
        },
        start(){
            this.show_seconds = true;
            this.passed = 0;
            this.updateSec();

            this.timer = setInterval(
                () => { 
                    this.passed += 1;
                    this.updateSec();

                    if(this.data.waiting == this.passed)
                    {
                        this.show_seconds = false;
                        clearInterval(this.timer);
                        this.$emit('ended')

                        // console.log('clearInterval', this.timer)
                    }
                 }
                , 1000
            )
            // console.log('timer start', this.timer)
        },
        updateSec(){
            this.lastSec = `${this.data.waiting - this.passed}초`;
        },
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.indicator {
    position: absolute; 
    bottom: 10px;
    right: 10px; 
    z-index: 99;
    font-size: 12px;
    color: white;
    /* background: white; */
    background-color: rgba(0, 0, 0, 0.4);
    padding: 10px;
    border-radius: 15px;
}

</style>



