<template>
    <div class="cell-container" style="text-align: center;">
        <!-- <div>나의 체리</div> -->
        <!-- <div class="cherry color-white background-primary"> {{ point }}</div> -->
        <div style="display: flex; width: fit-content; margin: 20px auto">
            <logo-image :size="size" />
            <div class="cherry color-white background-primary">{{ point }}</div>
        </div>
    </div>
</template>



<script>
import LogoImage from './LogoImage.vue';
// import common from '@/common'
// import constVal from '@/const'

export default {
  components: { LogoImage },  
    props:{
      point: Number,
    },
    data: function () {
      return {  
        size: '40px',
      };
    },
    mounted() {
    },
    methods: {
      click(param){
        param;
        this.$emit('clicked', this.data)
      },
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.cherry {
    font-size: 20pt;
    font-weight: bold;    

    color: white;
    width: fit-content;
    padding: 5px 20px 8px;
    border-radius: 16px;
    margin: 0px 10px;
}
</style>