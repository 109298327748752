const err_code = {
    not_login: 100,
    not_location: 99,
}

const brand = {
    name: '방',
    name2: '방을',
    name3: '방과',
    name4: '방은',
    name5: '방이',
}

const value = {
    post_len: 20,
    max_length: 10, // 채널제목 최대 길이
}

const notify = {
    post: 'post',   // 포스트 작성, 삭제, 업데이트 등 board_idx
    post_update: 'post_update', // post_idx
    login: 'login',
    nickname: 'nickname',
    post_del: 'post_del', // post_idx
    bookmark: 'bookmark', // 게시판 구독
    like_persona: 'like_persona', // 평점수정
    block_persona: 'block_persona', // 차단했다.
    last_message: 'message', // 마지막 메시지를 받는다.
    email: 'email', 
    charge: 'charge', // 충전글
    account: 'account',
    user: 'user', 
    dist_done: 'dist_done', // 배포신청완료 
    pay_done: 'pay_done', // 입금확인 요청
    done: 'done', // 리워드 받음
    sms_sent: 'sms_sent', // 문자메시지를 보냄
    phone_reg_done: 'phone_reg_done', // 전화번호 인증 완료 
}

const message = {
    no_more: '더 이상 없습니다.',
    fetch: '불러오는 중...',
    not_visited: '12시간안에 방문한 적이 있는 장소만\n글 작성이 가능합니다.',
    too_short: `본문은 최소 ${value.post_len}이상으로 작성해주세요.`,
    saved_location: `현재위치가 방문장소에 저장되었습니다.`,
    no_new: '새로운 글이 없습니다',
    memo: '기억하기 쉽게 메모해보세요',
    warning_needs: '충전금액이 부족합니다.',
}

const reportType = {
    not_policy: '존칭을 사용하지 않음',
    bad: '부적절한 내용의 글',
    offensive: '공격/비방글',
    ad: '광고/홍보글',
    etc: '기타',
}

const component = {
    SettingView: 'SettingView',
    About: 'About',
    MyPostsView: 'MyPostsView',
    ChargeListView: 'ChargeListView',
    ChildListView: 'ChildListView',
    ExchangeListView: 'ExchangeListView',
    PointLogListView: 'PointLogListView',
    AdminChargeListView: 'AdminChargeListView',
    PaymentListView: 'PaymentListView',
    MainView: 'MainView',
    BoxView: 'BoxView',
    SavedView: 'SavedView',
    PostView: 'PostView',
    MemberView: 'MemberView',
    NoticeView: 'NoticeView',
    QrcodeVue: 'QrcodeVue',
    SearchPage: 'SearchPage',
    SearchMain: 'SearchMain',
    LocalView: 'LocalView',
    MonitorView: 'MonitorView',
    PostForm: 'PostForm',
    ChargeForm: 'ChargeForm',
    DistForm : 'DistForm', // 배포량 추가
    DepositForm: 'DepositForm', // 입금확인
    ExchangeForm: 'ExchangeForm', // 포인트 교환
    ChargeList: 'ChargeList',
    JoinForm: 'JoinForm',
    EmailForm: 'EmailForm',
    AccountForm: 'AccountForm',
    ParentForm: 'ParentForm',
    NaverForm: 'NaverForm',
    MyChannelView: 'MyChannelView',
    LoginForm: 'LoginForm',
    ForgotPassword: 'ForgotPassword',
    MyTest: 'MyTest', 
    SlideNavigator: 'SlideNavigator',
    SendAuth: 'SendAuth',
    PhoneReg: 'PhoneReg',
    ReplyView: 'ReplyView',
}

var _base = process.env.NODE_ENV == 'production' ? process.env.VUE_APP_SERVER : `${window.location.protocol}//${window.location.hostname}:8001`;

const _api = _base + "/api/";

const my_like_post = '나의관심';  // 관심게시판의 글
const my_boards = '나의관심';  // 관심게시판의 글



const world_board = '세상의 관심';
const around_board = '주변의 관심';

const around_post = '주변최신글';
const recent_post = '세상글';
const helloTestUser = `
안녕하세요.

테스트 참여에 감사드립니다.

현재 서비스 테스트 중으로 가입절차를 생략하고 이 디바이스에 임시계정을 생성합니다.

다른 브라우저를 이용하면 새로운 계정으로 테스트 할 수 있습니다.
`
const testAccount = `
테스트계정을 힘들게 생성했어요. 

이제 자동로그인상태로 이용하실수 있습니다.
`;

const name = `
    CherryMoa
`

export default {
    _base,
    _api,
    err_code,
    my_like_post,
    my_boards,
    world_board,
    around_board,
    around_post,
    recent_post,
    helloTestUser,
    testAccount,
    notify,
    message,
    reportType,
    value,
    component,
    name,
    brand,
}

