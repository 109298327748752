<template>    
    <intro-view :data="rows" />
</template>

<style scoped>
@import '@/assets/common';
@import '@/assets/intro';

</style>

<script>

// import Const from '@/const'
import axios from 'axios'
import IntroView from './IntroView.vue';
export default {  
    setup() {
        return {
        };
    },
    components: {
        IntroView,
    },  
    props:{
        urls: Array
    },
    data() {
        return {  
            rows: [],
        };
    },
    mounted() {
        console.log('FetchView', this.url)

        const rows = new Array(this.urls.length);

        const fetch = async (index) => {
            const url = this.urls[index];
            
            console.log(url)

            const proc = (data) => {
                // console.log(data)

                rows[index] = data.data; // html 

                index++;
                
                if(index < this.urls.length) fetch(index);
                else this.rows = rows;
            }
            axios.get(url).then(proc)
            .catch(error => {
                console.error(error);
            });    
        }

        fetch(0);
    },
    methods: {
    },      
}
</script>

