<template>
    <div class="flex border">
        <div class="name">{{name}}</div>
        <address-button v-if="address && address.length>0" class="margin-left" :data="{name: address, location_idx: location_idx}" v-on:clicked="click"/>
        <span v-else class="material-symbols-outlined margin-left font-size15 color-gray">language</span>
    </div>
</template>

<script>
// import common from '@/common'
import AddressButton from './AddressButton.vue';

export default {
  components: { AddressButton },  
  props:{
      name:String,
      location_idx: Number,
      address: String,
    },
    mounted() {
        // console.log('boardcell.data', this.data)
        // console.log('this.props.index', this.data.index)
    },
    data: function () {
      return {  
        //   name: value
      };
    },
    methods: {
      click(){
          this.$emit('clicked', this);
      },
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.name{
    font-weight: bold;
}

.post-container {
  margin: 10px 0;
}

.header {
  margin-bottom: 3px;
  color: darkgray;
  font-size: 14px;
}

.post-container{
  padding: 0 10px;
}

/* .post-container img {
  float: left;
  margin-right: 20px;
  border-radius: 5%;
} */

.post-container .info {
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.post-container span {
  font-size: 20px;
  /* margin-right: 15px; */
}


.post-container .hash {
  /* background: yellow; */
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.post-container .like {
  /* background: yellow; */
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}

#location { 
  margin-left: 5px;
  color: gray;  
  /* background: dodgerblue; */
  display: inline-block;
  
  /* border: 1px solid gray */
}

#bottom {
  margin: 5px 0 5px;
}

</style>

