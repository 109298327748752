<template>
  <div >
    <title-bar-ex ref="titlebar" :title="title" :data="titleBarConfig" :right="{}" />
    <!-- <email-info v-if="user.idx > 0 && this.news.length == 0" :email="user.email" v-on:reg="onEmail" /> -->
    
    <!-- <rate-rect v-if="true" style="height: 100vh; margin: 0 auto" :rate="0.1" />
    <square-rect v-if="false">hello</square-rect> -->
    <info-card v-if="show_phone" style="margin-top: 30px;" :info="phone_text" :button_title="'등록'" :onclick="onPhone" />
    <div class="" style="position:relative; overflow: hidden; width: 100%" >
        <div v-if="logined == true">
            <div :style="{ margin: '0 auto',  width: containerWidth + 'px' }">
                <vertical-swiper v-if="all.length > 0" 
                    ref="vertical" 
                    :rows="all" 
                    :param="{onEvent}" 
                />
                <div v-else >
                    <no-data v-show="fetched" :data="nodata" /> 
                </div>
                
            </div>
        </div>
        <div v-if="logined == false">
            <!-- <div>비로그인</div> -->
            <intro-main />
        </div>
        <email-info v-if="needs_email" :email="user.email" v-on:reg="onEmail" />
    </div>

    <slidemenu ref="slidemenu" v-on:move="onMove"></slidemenu>
  </div>
</template>

<script>

import axios from 'axios'
import common from '@/common'
import Const from '@/const'
// import TabMenu from '@/components/common/TabMenu.vue'
import TitleBarEx from './common/TitleBarEx.vue'
// import TagBar from '@/components/common/TagBar.vue'
import slidemenu from '@/components/SlideMenu'
import VerticalSwiper from './VerticalSwiper.vue'
import NoData from './common/NoData.vue'
import IntroMain from './Intro/IntroMain.vue'
import EmailInfo from './common/EmailInfo.vue'
import InfoCard from './common/InfoCard.vue'
// import TextButton from './common/TextButton.vue'
// import RateRect from './container/RateRect.vue'
// import SquareRect from './container/SquareRect.vue'

  const str_my = '나의 호감멤버'
  const str_popular = '인기멤버';
  const str_all = '전체글';
  // const str_newone = '새멤버';
  const str_hot = '추천순';
  const tab_menus = [str_all, str_popular, str_my]; // 

  const str_empty = '같은 관심사를 가진 사람들과 \n익명으로 자유롭게\n 이야기를 나눠보세요'
  const pull_new = "새글 확인하기";

  export default {
    components: {
        slidemenu,
        // TagBar,
        // NoMore,
        TitleBarEx,
        // slideview,
        // IntroMain,
        // NoData,
        // SlideCell,
        VerticalSwiper,
        NoData,
        IntroMain,
        EmailInfo,
        // TextButton,
        // RateRect,
        // SquareRect,
        InfoCard,
    },

    props: {
      param: Object,
      container: Object,
    },
    setup() {
      // console.log('board setup');
      return {
      }
    },
    data(){ 
      return {
        phone_text: `체리메시지를 받기 위해서 
우선 사용자의 휴대폰 번호를 등록하여
가입을 완료하세요.`,
        show_phone: false,
        needs_email: false, // 이메일 등록이 필요함
        fetched: false,
        containerWidth: 0,
        index: 0,   // 슬라이드 인덱스 
        logined: undefined, // 로그인 여부
        nodata: {
            html: '수신함이 비어 있습니다.'
        },
        running: null, // 진행중인 슬라이드
        updated: false,
        elementRefs: [],
        title: `<div style="display:flex; font-size: 18px;"><img src="/cherry.svg" style="margin-right:5px;height:20px; " /><div class="color-primary cherry-font" >${Const.name}</div></div>`,
        row: {
        },
        news: [],
        dones: [],
        all:[],
        last: 0,
        total: 0,
        left:[
          {
            html: '<i class="fa fa-chevron-left"></i>',
            onClick: this.onLeft,
          },
          {
            html: '<span class="material-symbols-outlined ">home</span>',
            onClick: this.onHome,
          },
        ],
        titleBarConfig:{
            is_write: false, 
            is_menu: true,
            left: [
                {
                    html: `<i class="button-color fa fa-bars navi-button"></i>`,
                    onClick: () => {this.$refs.slidemenu.is_show = true;}
                },
                // {
                //     html: `<div class="navi-button" style="font-weight: 900;">${Const.name}</div>`
                // }
            ]
        },        
        writable: false, // 이 게시판에 글을 작성 할 수 있는가?
        board_name: '',

        str_all,

        tab_menus,

        me: null,
        idx: 0,
        needs_more: true,
        selectedTab: tab_menus[0],
        hashtag:['전체'],
        str_empty,
        pull_new,

        reported:{}, // 신고한 사람
        top_post: null,
      }
    },    
    created: function () {
        this.idx = common.param("idx");
    },
    mounted: function() {
        console.log('MainView.mounted', this.user)            
        {
            // 현재 브라우저 창의 너비와 높이를 얻어옴
            var screenWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
            var screenHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

            console.log('screenWidth, screenHeight', screenWidth, screenHeight)

            this.containerWidth = screenWidth;

            if((screenWidth / screenHeight) > 0.66) // 너무 넓으면
            {
                console.log('screenWidth / screenHeight', screenWidth / screenHeight)
                const re = screenHeight * 0.66;

                setTimeout(() => {
                    console.log('re, this.ui.width', re, this.ui.width)
                    if(re < this.ui.width) 
                        this.containerWidth  = re;
                    else
                        this.containerWidth = this.ui.width;
                }, 1);
            }
        }       

        // console.log('this.param', this.param)

        {
            const comp = {
                onEvent: this.onEvent,
                onShow: this.onShow,
            }
            common.registEventHandler(comp);
        }
        
        // 로그인상태 체크
        {
            const url = `${Const._api}me`
            console.log(url)

            axios.defaults.withCredentials = true;
            axios.get(url).then(response => {
                const data = response.data;
                console.log(data)
                const {error, user, admin, msg, joined} = data;

                if(error > 0){
                    console.log(msg)

                    if(error == 100) // 로그인 상태가 아니면
                    {
                        this.logined = false;
                        this.$refs.slidemenu.is_login = false;
                        // console.log('비로그인 상태')
                    } 
                    else{
                        console.log(msg);
                    }
                }
                else // 로그인 상태
                {
                    console.log('로그인 상태', user)
                    this.didLogin(user)
                    this.logined = true;

                    Object.assign(this.user, user);

                    this.$refs.slidemenu.is_login = true;
                    this.$refs.slidemenu.is_admin = admin ? true : false;

                    const parent = common.getLocalParent()

                    if(parent && joined) // 추천인코드를 통해 가입과정이라면
                    {
                        console.log('mounted.joined>', joined)
                        common.popUp({
                            name: Const.component.ParentForm,
                            param: {
                                caller: this
                            }
                        })
                        return;
                    }
                } 

                this.$refs.titlebar.updateButtonState()

                // 파라미터 체크
                {
                    const pathname = location.pathname;
                    console.log('pathname', pathname);

                    const arr = pathname.split('/');

                    if(arr.length > 2){
                        console.log('arr', arr);
                        const type = arr[1];

                        switch (type) {
                            case 'ad': // ad
                            {
                                const idx = arr[2]
                                const hash = arr.length > 3 ? arr[3] : 0;
                                console.log('type, idx, hash>', type, idx, hash);
                                common.push({
                                    name: Const.component.PostView,
                                    param: {idx, hash}
                                })
                            }
                            break;
                            case 'invite':
                                console.log('invite', arr)

                                this.initView();

                                if(this.user.idx > 0) // 이미 로그인 중
                                {
                                    console.log('로그인상태', this.user)
                                    return;
                                } 

                                if(arr.length == 4) {
                                    const idx = arr[3];

                                    // 이벤트가 유효한지 확인한다.
                                    {
                                        const proc = (data) => {
                                            console.log(data);

                                            if(idx == data.idx) // 현재 이벤트중이다.
                                            {
                                                common.setEventIdx(idx);

                                                const parent = arr[2];
                                                common.setLocalParent(parent);

                                                console.log('추천인코드 저장완료')
                                            }
                                            else{
                                                // 현재 이벤트 내용이 다르다.
                                                const param = {
                                                    header: '무효한 초대코드입니다.',
                                                    body: '추천인에게 코드가 무효하다고 알려주세요.'
                                                }
                                                common.popupModal(param)
                                            }
                                        }

                                        const url = `${Const._api}invite/last`
                                        axios.get(url).then(common.checkResponse(proc))
                                        .catch(error => {
                                            console.error(error);
                                        });                                         
                                    }

                                }
                            break;
                            default:
                                break;
                        }
                    }
                    else{
                        this.initView();
                    }        
                } 
            })
            .catch(error => {
                console.error(error);
            });   
        }
    },
    methods:{
        onPhone(){
            const obj = {
                name: Const.component.PhoneReg,                            
            }
            obj;
            common.popUp(obj)            
        },
        onClosePop(id){
            console.log('onClosePop', id)
            switch (id) {
                case Const.component.ParentForm:
                    this.initView();
                    break;
            
                default:
                    break;
            }
        },
        requestInBox(){
            this.news = [];
            this.dones = [];
            this.all = [];

            console.log('requestInBox')
            const url = `${Const._api}my/box/0`
            console.log(url)

            axios.defaults.withCredentials = true;        
            const proc = (data) => {

                const {error} = data;
                if(error > 0)
                {
                    if(error == 1) // email 등록을 안함
                    {
                        this.needs_email = true;
                        this.onEmail()
                        return;
                    }
                    else if(error == 2)
                    {
                        this.show_phone = true;
                        const obj = {
                            name: Const.component.PhoneReg,                            
                        }
                        obj;
                        // common.popUp(obj)
                        return;
                    }
                }

                console.log(data)
                const {news, dones} = data; // 신규, 본것
                console.log('news>', news)
                console.log('dones>', dones)

                this.fetched = true;

                if(news.length > 0){
                    news.forEach(element => {
                        console.log('element>', element)
                        element.logined = true;
                        element.pickable = true;
                    });
                }                            
                this.news = news;

                this.dones = dones;

                this.all = this.all.concat(news)
                this.all = this.all.concat(dones)

                // this.all = [this.all[0], this.all[2], this.all[3]]//, this.all[4]];

                console.log('this.all>', this.all)

                this.total += news.length + dones.length;
                

                // util.show('container')

                this.updated = false;
                setTimeout(() => {
                    // console.log('called')
                    this.didUpdated() // 랜더링 할 기회를 주고 호출한다.
                }, 1)
            }
            axios.get(url).then(common.checkResponse(proc))
            .catch(error => {
                console.error(error);
            });    
        },
        onEmail(){
            console.log('이메일 등록하기')
            console.log('onEmail')
            common.popUp({
                name: Const.component.EmailForm,
                param: {}
            })
        },
        onStart(slide){
            console.log('onStart', slide)

            if(this.running) this.running.resetSlide();

            this.running = slide;
        },
        didUpdated(){
            if(this.updated == false){
                this.updated = true;
                // console.log('updated')

                if(this.elementRefs.length > 0){
                    const element = this.elementRefs[0]
                    console.log('element>', element)
                    this.requestOne(element.ad, element.update)
                }
            }
        },
        onSelected(elem){
            console.log('onSelected', elem.row)

            const {data} = elem;
            this.requestOne(data, (res) => {
                elem.update(res)
            })
        },
        setRef(element) {
            console.log('setRef', element)
            // 요소의 참조를 배열에 추가합니다.
            this.elementRefs.push(element);
        },
        requestOne(row, cb){
            // console.log('requestOne', row)
            const url = `${Const._api}adv/${row.idx}/${row.hash}`
            // const url = `${host}/api/my/ad/${idx}`
            // console.log(url)
            axios.defaults.withCredentials = true;
            axios.get(url).then(response => {
                const data = response.data;
                // console.log(data)
                const {error, row, msg} = data;

                if(error > 0){
                    console.log(msg)
                    return;
                } 

                row.logined = row.needLogin != 'y';
                // console.log('row>', row)

                cb(row);
            })
            .catch(error => {
                console.error(error);
            });
        },        
        scrollToBottomSmallTalk(){
            setTimeout(this.scrollToElement, 1);
        },
        showReportType(post_idx, cb){
            const menus = [];
            for (let key in Const.reportType) {
            menus.push({
                title : Const.reportType[key],
                cb: () => { cb(key)},
            });
            }

            const obj = {
            title: '신고사유를 선택해주세요',
            menus
            }
            common.showMenuEx(obj);                
        },      
        onFlag(){
            // console.log('onFlag', this.board)
            const url = Const._api + "board/flag";
            common.api_post(url, 
            {
                board_idx : this.board.idx,
            }, 
            () => {
                // console.log('성공')
                this.board.persona_idx = this.persona.idx
            },
            )                        
        },
        onUnflag(){
            // console.log('onFlag', this.board)
            const url = Const._api + "board/unflag";
            common.api_post(url, 
            {
                board_idx : this.board.idx,
            }, 
            () => {
                // console.log('성공')
                this.board.persona_idx = 0;
            },
            )                        
        },
        onWriteTop(){
            // console.log('onWriteTop');

            const param = {
                data: {
                    board_idx: this.idx,
                    idx: 0,
                    topfix: 1,  // 상단고정글
                }
            } 

            const obj = {
                name: Const.component.PostForm,
                param
            }
            common.popUp(obj);
        },
        scrollToElement() {
            const el = this.$refs.scroll_container;
            // console.log(el);


            if (el) {
            el.scrollTop = el.scrollHeight;
            }
        },
        didTalk(){
            // console.log('didTalk');

            // 이후의 메시지를 요청한다.
            this.requestSmallTalkNew();
        },
        onTouchMove(item){
            item
            // console.log('onTouchMove',item)
        },
        onShow(){
            this.initView();
        },
        onMove(location_idx){
                // console.log('onMove', location_idx)

                this.$refs.locationbar.movePosition({location_idx})
            },

        onPull(){
            switch (this.selectedTab) {
            case str_my:
                break;
            case str_hot:
                
                break;        
            default:
                break;
            }
        },
        initView(){
            console.log('initView')
            if(this.logined) this.requestInBox();
        },
        onEvent(name, param){
            console.log('onEvent', name, param)

            switch (name) {
            case Const.notify.phone_reg_done:
                this.show_phone = false;
                this.requestInBox();
                break;
            case Const.notify.user:
                location.reload();
                break;
            case Const.notify.done:
                break;
            case Const.notify.email:
                {
                    // const {email} = param;
                    // this.user.email = email;
                    this.needs_email = false;
                    this.requestInBox();
                }
                break;
            case Const.notify.post:
            case Const.notify.post_del:
            case Const.notify.post_update:
                break;
            case Const.notify.login:
                {
                // console.log('onEvent', param);
                this.initView();
                }
                break;
            default:
                break;
            }
        },
        didLogin(user){
            // titlebar 수정
            this.me = user;
            this.session.user = user;

            this.$refs.titlebar.didLogin();
            this.$refs.titlebar.updateButtonState()
        },      
        notLogin: function(){    
            // 인트로 가져오기
            {
                const url = `${Const._api}adv/1/null`
                // console.log(url)

                common.api_get(
                    url, 
                    res => {
                        const {error, row, msg} = res.data;
                        if(error > 0){
                            const param = {
                                header: '잘못된 접근',
                                body: msg,
                            }
                            common.popupModal(param);
                            
                            return;
                        }

                        console.log(row)
                    }
                );              
            }

        },
        fitToBubble(rows){
            rows.forEach(element => {
            element.my_persona_idx = this.persona.idx;
            if(element.persona_idx == this.persona.idx) element.myname = element.persona_idx;
            if(this.reported[`${element.persona_idx}`]) element.hidden = true;
            if(element.nickname) element.name = `${element.persona_idx}(${element.nickname})`
            });
        },
        requestAll(start=0){
            console.log('requestAll',start)   
        },
        onTitle(){

        },
        onLeft(){
            common.back();
        },
        pullNew(target, isMerge = false, rate = 0){             
            if(isMerge == false && target.isNew) return; // 이미 새글이 있다.
            
            const after = target.rows.length > 0 ? target.rows[0].idx : 0
            const url = Const._api + `board/new/${this.idx}/${after}/${rate}`;

            common.api_get(
            url, 
            res => {
                const {error, msg} = res.data;
                if(error > 0){
                const param = {
                    header: '잘못된 접근',
                    body: msg,
                }
                common.popupModal(param);
                
                return;
                }
                
                // this.snackbar.hide();

                const {rows} = res.data;

                if(rows.length == 0){
                common.toast('새로운 글이 없습니다.')
                return;
                }

                if(isMerge){
                target.isNew = false;
                rows.forEach(element => {
                    element.url = Const._base + element.url;
                });
                target.rows = rows.concat(this.rating.rows);  
                }
                else{
                target.isNew = true; // 표시만 한다.
                }
            }
            );
        },
        checkNewAll(){
            const last = this.all.rows.length > 0 ? this.all.rows[0].idx : 0;
            const url = Const._api + `post/board/new/all/${this.idx}/${last}/${this.tag}`;

            common.api_get(
            url, 
            res => {
                const {error, msg} = res.data;
                if(error > 0){
                const param = {
                    header: '잘못된 접근',
                    body: msg,
                }
                common.popupModal(param);
                
                return;
                }

                const {isNew} = res.data
                this.all.isNew = isNew;

                this.$refs.alert_all.alert(isNew);
                // if(!isNew) common.toast(Const.message.no_new)
            }
            );        
        },
        onScrollTop(){
            switch (this.selectedTab) {
            case str_all:
                break;
            default:
                break;
            }
        },
        onScrollBottom(){
            // console.log('onScrollBottom')
            switch (this.selectedTab) {
            case str_all:
                {
                if(this.all.end) return;
                const last = this.all.rows.length > 0 ? this.all.rows[this.all.rows.length - 1].idx : 0
                this.requestAll(last)
                }
                break;
            default:
                break;
            }
        },
        onScrollEnd: function(){

        },
        onNavbar: function(button){
            // console.log('onNavbar', button);
            switch (button) {
                case 'search':
                    this.$emit('event', 'home');
                    // {
                    //     const obj = {
                    //         name: 'SearchPage',
                    //     }
                    //     common.popUp(obj);
                    // }
                    break;
                case 'login':                   
                    // {
                    //     const param = {
                    //         didLogin: this.initView
                    //     }
                    //     common.popupLogin(param)
                    // }
                    break;
                case 'bookmark':
                    this.onLike();
                    break;
                case 'share':
                    {
                    // console.log('this', window.location);
                    const persona_idx = this.persona ? this.persona.idx : 0;
                    const param = {
                        url: `${window.origin}/?/board/${this.idx}/${persona_idx}`,
                    } ;
                    // console.log('param', param);

                    common.popUp({
                        name: Const.component.QrcodeVue,
                        param
                    })
                    }
                    break;
                case 'write':
                    if(this.writable == false){
                    const param = {
                        header: '',
                        body: Const.message.not_visited,
                    }
                    common.popupModal(param);                  
                    return;
                    }

                    this.onWrite();       
                break;
            
                default:
                break;
            }
        },
        onWrite(){
            // console.log('this.me', this.me);
            if(this.me)
            {
            const param = {
                data: {
                board_idx: this.idx,
                idx: 0,
                }
            } 

            const obj = {
                name: Const.component.PostForm,
                param
            }
            common.popUp(obj);
            }
            else // 로그인을 한번도 한적이 없는 경우 
            {
            common.popupLogin();
            }         
        },
        onHome(){
            this.$emit('event', 'home');
        },
        onLike(){
            const url = Const._api + "board/like";
            const param = {
            board_idx: this.idx,
            like: !(this.board.isLike),
            }
            common.api_post(url, param, data => {
                // console.log('data', data)

                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    // console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }
                // this.$emit('done');
                this.board.isLike = !(this.board.isLike) ? 1 : 0;
                // this.$refs.navbarBottom.bookmark = this.board.isLike ? "bookmark-on" : "bookmark-off" ;

                common.notifyEvent(Const.notify.bookmark)
            })
        },
        onTabMenu(menu){
            this.selectedTab = menu;
            // console.log('index.selectedTab', menu, param);

            switch (menu) {
            case str_all:
                if(this.all.rows.length == 0) this.requestAll();
                break;
            default:
                break;
            }
        },
        onTag(tag, n){     
            // console.log('onTag', tag, n)
            // tag;     
            // this.tag = this.tags[n-1]

            if(n == 0){ // 전체
            this.tag = '*'
            }else{
            this.tag = this.tags[n-1]
            }

            switch (this.selectedTab) {
            case str_all:
                this.requestAll();
                break;
            case str_my:
                this.requestMy();
                break;
            case str_popular:
                this.requestPopular();
                break;
            
            default:
                break;
            }
        },
        // 현재 선택탭을 리로드하고 나머지 탭은 비운다.
        reloadSelectedRows(){
            {
            // 데이터 리셋
            const reset = (target) => {
                target.rows = [];
                target.isNew = false;
                target.end = false;
                target.handleScroll = false;
            }

            reset(this.all);
            reset(this.myMembers);
            reset(this.popular);
            reset(this.newone);
            reset(this.hot);
            reset(this.smalltalk);
            }
        
            switch (this.selectedTab) {
            case str_all:
                this.requestAll();
                break;            
                default:
                break;
            }
        },
        popupSlide(data, index, rows){
            const obj = {
                name: Const.component.SlideNavigator,
                param: {
                    data,
                    index,
                    rows,
                    closeAll: this.onCloseAll,
                    onEvent: this.onEvent,
                }
            }
            common.popUp(obj)            
        },
        onPost(index){

            console.log('onPost', index);
            
            // common.push({
            //     name: Const.component.PostView,
            //     param: data,
            // })  ;

            const data = this.all[index];
            
            this.popupSlide(data, index, this.all)
        },
        onNext(){
            console.log('onNext')
            const index = this.index + 1;
            this.index = index;
            const data = this.news[index];
            this.popupSlide(data)
        },
        onCloseAll(){
            console.log('onCloseAll')
            common.closeAllPop();
        },
        onDone: function(){
            // 글쓰기를 하면 무조건 전체글 탭으로 이동한다.
            this.selectedTab = str_all
            this.requestAll();

            // 다른탭에는 영향을 주지 않으므로 그냥 둔다.
        },
        onError: function(error, param){
            // console.log('onError', error, param);

            // todo : notlogin 처리 / 리책토링

            common.toast(param)
        },
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>

.empty {
  height: 200px;
}

.cherry-font{
    font-family: 'Archivo', sans-serif;
}

.slide-view {
    margin: 0 10px 10px;
    border-bottom: solid gray 0.5px;
}

.refresh {
  text-align:center; 
  height:20px;  
  font-size: 12px;
  color: white;
  margin: 10px 0;
  background: dodgerblue;
}

.top-page {
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
  /* left: 0; */
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

.seperator {
    text-align: center;
    font-size: 13px;
    color: gray;
}
</style>

