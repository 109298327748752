<template>
    <div class="cell-container" style="text-align: center; margin-top: 10px;">
        <div v-html="data.html"></div>
    </div>
</template>

<style scoped>
@import '@/assets/common';

</style>

<script>
export default {  
    props:{
      data:Object,
    },
    mounted() {
        console.log('data', this.data)
    },
    data: function () {
      return {  
        //   name: value
        selected: 0,
      };
    },
    methods: {
      click:function(data, n){
          this.selected = n;
          this.$emit('clicked', data, this.id)
      },
    },      
}
</script>

