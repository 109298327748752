<template>  
    <div style="background-color: white;">
        <div id="card_container" v-show="ad.htmls && ad.htmls.length > 0">
            <Swiper  id="slideview-swiper" 
            style="display:block; width: 100%;" 
            :modules="modules" 
            :pagination="{ clickable: false }" 
            @swiper="onSwiper"
            @slideChange="onSlideChange"
            @resize="onResize"
            >
                <SwiperSlide v-for="(item, index) in ad.htmls" v-bind:key="index" style="margin: auto 0;" >
                    <div style="width: 100%" v-html="item"></div>
                </SwiperSlide>
                <SwiperSlide v-show="show_last" >
                    <div class="swiper-slide" style="height:100%">
                        <div id="last" class="center-box">
                            
                            <div :style="{visibility: last_visibility}">
                                <div v-if="str_error.length > 0">{{ str_error }}</div>
                                <div v-else>
                                    <div class="center-h">
                                        <!-- <div id="plus" class="point">{{str_point}}</div> -->
                                        <div style="display: flex;">
                                            <logo-image :size="'20px'" /><div>{{str_point}}</div>
                                        </div>
                                    </div>
                                    <div >

                                        <my-cherry :point="Number(str_amount)" />

                                        <div class="height-10"></div> 
                                        <pre class="comment">500체리를 모으면
    네이버페이 500포인트로 교환이 가능합니다. </pre>    
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </SwiperSlide>
                <slide-timer v-show="show_indicator" 
                    ref="indicator" 
                    :data="timer" 
                    v-on:ended="onTimerEnd"
                />
            </Swiper>
            <div class="between" @click="onTitle">
                <slide-cell :data="ad" />
            </div>
            <ButtonBar 
                ref="buttonBar" 
                :buttons="buttons" 
                v-on:clicked="onNavbar" 
                style="border-bottom: initial; border-top: initial;"
            />
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
// import Navbar from '@/components/common/NavBar'
import ButtonBar from '@/components/common/ButtonBar'

import Const from '@/const'
import SlideTimer from './common/SlideTimer.vue';
import SlideCell from './common/SlideCell.vue';
import MyCherry from './common/MyCherry.vue';
import LogoImage from './common/LogoImage.vue';

// const str_report = '신고'
// const str_block = '차단'
// const str_unblock = '차단해제'
// const str_info = '작성자 정보'
const str_share = '공유'

export default {
    components: {
        Swiper,
        SwiperSlide,
        // Navbar,
        ButtonBar,
        SlideTimer,
        SlideCell,
        MyCherry,
        LogoImage,
    },
    props: {
        data: Object
    },
    setup() {
        return {
            modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },

    data() {         
      return  {
        str_error: '', // 에러메시지
        timer:{}, // 타이머 파라미터
        ad:{ },
        started: false, // 열람 시작 여부?
        swiper: null,
        buttons: [        
            {
                html: '<span :class="bookmark" class="material-symbols-outlined">bookmark</span>',
                onclick: () => {
                    // console.log('좋아요')

                    const proc = (data) => {                                
                        if(data.error == 0){
                            // console.log('저장완료', data)
                            const {state} = data;
                            this.ad.like = state;

                            this.updateBookmark(this.ad.like)
                        }else{
                            console.log('에러발생')
                        }
                    }

                    {
                        const {idx, like} = this.ad;
                        // console.log('idx',idx)
                        const param = {
                            idx,
                            state: like ? 0 : 1,
                        }
                        {
                            axios.post(`${Const._api}like/add`, param)
                            .then(common.checkResponse(proc))
                            .catch(error => {
                                console.log(error);
                            });
                        }        
                    }                    

                }
            },
            {
                html: '더 알아보기',
                onclick: () => {
                    console.log('더 알아보기', this.ad)

                    if(this.ad.idx == 1){
                        alert('리로드해요')
                        location.reload();
                    }
                    
                    {
                        const ad_idx = this.ad.idx;
                        axios.post(`${Const._api}visit`, {ad_idx} )
                        .then(response => {
                            const {data} = response;
                            if(data.error == 0){
                                console.log('방문기록 전달완료')
                            }else{
                                console.log('전달실패')
                            }
                        })
                        .catch(error => {
                            console.log(error);
                        });        

                    }

                    window.open(this.ad.url)
                }
            },
            {
                html: '<span class="material-symbols-outlined ">share</span>',
                onclick: () => {
                    console.log('공유하기')

                    this.popupShare()
                }
            },
            {
                html: '<span class="material-symbols-outlined ">chat</span>',
                onclick: () => {
                    console.log('작성자에게 글남기기')

                    common.push({
                        name: Const.component.ReplyView,
                        param: this.ad,
                    })  ;
                }
            }
        ],
        end: false,

        show_indicator: false,
        show_last: true,
        show_reward: false,

        last_visibility: 'hidden',

        str_point: '',
        str_amount : '',
      }
    },
    created () {
      // console.log('created')
    },
    mounted() {           
        // console.log('mounted', this.data)
        // console.log('this.user', this.user)

        if(this.data){
            this.ad = this.data;
            this.updateBookmark(this.data.like)

            this.update(this.data)
        } 
    },    
    unmounted(){
    },
    computed:{

    },
    methods:{
        onResize(){
            // console.log('onResize')
            // console.log('this.ui.width', this.ui.width)
            const swiper = document.getElementById('slideview-swiper');
            swiper.style.height = `${this.ui.width}px`;
        },
        popupShare(){
            const {idx, hash} = this.ad;
            common.popupShare(idx, hash)
        },        
        start(){
            // console.log('start', this.data.idx)
            if(this.ad.pickable){
                this.started = true;

                this.$refs.indicator?.start();

                this.$emit('onStart', this);
            }
        },
        slideTo(index, cb){
            this.swiper.slideTo(index, 500, cb)
        },
        resetSlide(){ // 슬라이드를 첫페이지로 이동하고 / 타이머를 멈춘다.        
            // console.log('resetSlide', this.data.idx, this.end)
            if(this.end) return;

            // console.log('resetSlide.stop', this.data.idx)
            this.$refs.indicator?.stop();

            // console.log('allowTouchMove = false')
            this.swiper.allowTouchMove = false;
            this.started = false;
            this.slideTo(0)            
        },
        onSwiper(sw){
            // console.log('onSwiper')
            this.swiper = sw;
        },
        onSlideChange(){
            // console.log('onSlideChange')

            if(this.started == false){
                // console.log('return')
                return;
            } 

            if(
            (false == this.ad.logined) // 로그인이 필요하거나
            || this.ad.pickable == false
            ) {
                // console.log('return')
                return;
            }
            
            // console.log('this.swiper.activeIndex / this.ad.pages', this.swiper.activeIndex, this.ad.pages)
            // 모든 페이지를 다 봤다.
            if(this.swiper.activeIndex == this.ad.pages)
            {
                this.end = true;
                this.swiper.allowTouchMove = true;

                {
                    const cb = (data) => {
                        // console.log(response.data);
                        this.last_visibility = 'visible';
                        if(data.error == 0)
                        {
                            this.ad.pickable = false;
                            const {point} = data;
                            this.str_point = `+${this.ad.reward}이 적립되었습니다.`
                            this.str_amount = `${point}`;

                            {
                                common.notifyEvent(Const.notify.done)
                            }
                        }else{
                            // // console.log('에러발생', data.msg)
                            this.str_error = `오류발생 : ${data.msg}` 
                        }
                    }
                    
                    const url = `${Const._api}done`;
                    console.log(url, this.ad.idx)
                    axios.post(url, { ad_idx: this.ad.idx } )
                    .then(common.checkResponse(cb))
                    .catch(error => {
                        console.log(error);
                    });
                }
            }
            else
            {
                if(false == this.ad.logined){
                    console.log('로그인 필요')
                }
                else{
                    // console.log('터치 조정', this.end)
                    this.swiper.allowTouchMove = this.end; // 끝나지 않은 경우는 disable

                    this.$refs.indicator?.start();
                }
            }
            this.updateButton();                  
        },
        onTitle(){
            // console.log('onTitle')
            this.$emit('onTitle', this);
        },
        updateBookmark(like){
            let fill = like ? 'fill' : '';
            const html = `<span :class="bookmark" class="material-symbols-outlined ${fill}">bookmark</span>`
            this.$refs.buttonBar.update(0, html)
        },
        onNavbar: function(button){
            // console.log('onNavbar', button);
            switch (button) {
                case 'bookmark':
                    console.log('bookmark')
                    this.onLike();
                    break;
                case 'share':
                    {
                        console.log('share')

                        // const persona_idx = this.persona ? this.persona.idx : 0;
                        // const param = {
                        //     url: `${window.origin}/?/board/${this.idx}/${persona_idx}`,
                        // } ;
                        // common.popUp({
                        //     name: Const.component.QrcodeVue,
                        //     param
                        // })
                    }
                    break;
                default:
                break;
            }
        },              
        // onMenu(){ // 본문메뉴
        //     this.$emit('menu', this.ad);
        // },        
        onMenu(){ // 본문메뉴
            console.log('onMenu', this.user)
            const row = this.ad

            let arr = [];
            if(this.user == null){
                console.log(this.user)
            }
            else
            {
                if(this.user.idx == row.user_idx) // 자신이면
                { 
                    arr = [
                        // {
                        //   title : '추가배포',
                        //   cb: () => {
                        //       const obj = {
                        //         name: Const.component.DistForm,
                        //         param: {...this.data, chips: this.user.chips} 
                        //       }
                        //       common.popUp(obj);
                        //   },
                        // },            
                    ]          
                }
                else{
                    console.log('타인의 게시글')
                }

                arr.push(
                    {
                        title : str_share,
                        cb: () => {
                            this.popupShare();
                        }
                    }                    
                )

            }

            common.showMenu(arr)
        },
        update(row){
            // console.log('update', row)
            this.ad = row;

            if(row.htmls && row.htmls.length > 0)
            {
                row.htmls.length
            }
            else if(row.urls && row.urls.length > 0)
            {
                const temp = row.urls.trim();
                const urls = temp.split('\n');                
                const rows = new Array(urls.length);

                const fetch = async (index) => {
                    const url = urls[index];
                    
                    const proc = (data) => {
                        rows[index] = `<div class="card" 
                        style="display: flex; justify-content: center; align-items: center;" >
                        ${data.data}</div>` 

                        index++;
                        
                        if(index < urls.length) fetch(index);
                        else this.ad.htmls = rows;
                    }
                    axios.get(url).then(proc)
                    .catch(error => {
                        console.error(error);
                    });    
                }
                fetch(0);
            }

            const {waiting, idx} = row;
            this.timer.waiting = waiting;
            this.timer.idx = idx;

            this.updateBookmark(row.like)

            {
                if(
                    (false == (this.user.idx > 0)) // 로그인이 필요하다면...
                    || this.ad.pickable == false
                ) 
                {
                    this.hideReward()

                    if(false == this.ad.logined) // 비로그인
                    {
                        this.hideOne('save')
                    }
                }
                else if(this.ad.pickable == true){
                    this.show_reward = true;
                }

                if(this.ad.logined)
                {
                    this.hideAll('login')
                }    

                if((false == this.ad.logined) || this.ad.pickable == false)
                {
                    this.swiper.allowTouchMove = true;
                }
                else
                {
                    // console.log('allowTouchMove = false')
                    this.swiper.allowTouchMove = false; // 터치 막음

                }
            }

            this.updateButton()
        },
        hideAll(id){
            var myElements = document.querySelectorAll(`#${id}`);
            for (var i = 0; i < myElements.length; i++) {
                myElements[i].style.display = "none";
            }            
        },
        hideOne(id){
            const elem = document.getElementById(id);
            if(elem) elem.style.display = 'none';
        },
        hideReward(){
            console.log('hideReward')
            this.show_indicator = false;
            this.show_last = false;
            this.show_reward = false;
        },
        updateButton(){
            if(
                (false == this.ad.logined) // 로그인이 필요하거나
                || this.ad.pickable == false
            ) 
                return;
            
            if(this.end){
                this.show_indicator = false;
            }
            else{
                this.show_indicator = true;
            }
        },
        onTimerEnd(){
            console.log('onTimerEnd')
            this.swiper.allowTouchMove = true;
            this.updateButton();
        }
    },    
  }
</script>

<style >
@import '@/assets/common';
</style>

<style scoped>

.swiper {
  z-index: 0;
}

.tail {
  height: 240px;
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */

    margin-bottom: 40px;
}

.row {
    font-size: 20px;
    background: peachpuff;
}

.left {
  display: flex;
}

#repls {
  margin-bottom: 30px;
}

.ad {
    font-size: 16px;
    /* font-weight: bold; */
    margin: 5px 10px;
}

.amount {
    font-size: 20px;
    font-weight: bold;
    color: dodgerblue;        
    background: white;
    padding: 5px 10px;
    border: solid dodgerblue 1px;
    border-radius: 10px;
}

.text-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;        
    background: white;
}

.text {
    line-height: 2.5; 
    font-size: 16px;
    text-align: center;
    width: 100%;
    white-space: pre-wrap;
    font-family: 'Noto Serif KR', serif; 
    margin: 0;
}

.swiper-slide {
    margin: auto 0;
}
</style>

