<template>    
    <div id="card_container" ref="card_container"  class="intro-back">
        <Swiper id="introview-swiper" ref="swiper" style="width: 100%; height: 100%; "
            :modules="modules" 
            :pagination="{ clickable: false }" 
            @resize="onResize"
            >
            <SwiperSlide 
                v-for="(item, index) in data" 
                v-bind:key="index" 
                :ref="setRef"
                style="height: 100%; margin: auto 0;"
                >
                <div class="card" :style="style" v-html="item"></div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>


<script>
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'

// import Const from '@/const'
// import IntroCard from '../common/IntroCard.vue';
export default {  
    setup() {
        return {
            modules: [Navigation, Pagination, Scrollbar, A11y],
        };
    },
    components: {
        Swiper,
        SwiperSlide,
        // IntroCard,
    },  
    props:{
        data: Array,
    },
    mounted() {
        console.log('mounted', this.data)

        var body = document.body;
        var bodyWidth = body.offsetWidth;
        console.log("body의 폭: " + bodyWidth + "px");        
        this.style.height = `${bodyWidth}px`
    },
    updated(){
        console.log('updated')
    },
    data() {
        return {                 
            width: '',
            style: {
                width: '',
            }
        };
    },
    methods: {
        setRef(elem){
            elem
            // console.log('setRef')
        },
        onResize(){
            console.log('onResize', )

            let swiper = document.getElementById("introview-swiper");
            // const swiper = this.$refs.swiper;
            // console.log('swiper.style>', swiper.style)

            var body = document.body;
            var bodyWidth = body.offsetWidth;

            swiper.style.height = `${bodyWidth}px`            
        }
    },      
}
</script>

<style scoped >
@import '@/assets/common';
@import '@/assets/intro';

.icon {
    font-size: 80px;
  }

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
}

.card{
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100vw; */
}

</style>