<template>
    <div class="cell-container " @click="click">
        <!-- <p class="name">{{data.name}}</p> -->
        <div class="header">{{data.header}}</div>

        <div style="display: flex; align-items: center;">
          <div v-if="data.rank" class="rank">{{data.index + 1}}.</div>
          <board-title :name="data.name" :location_idx="data.location_idx" :address="data.address" v-on:clicked="click" />
        </div>

        <!-- 게시글 -->
        <p v-show="data.content" class="post-text post-font-family font-size14 color-gray" style="-webkit-line-clamp:2;">&#183; {{data.content}}</p>
        
        <div class="between margin-top10" >
          <div>
            <div class="cell-sub-color cell-sub-font">게시글 {{data.posts}}</div>
          </div>          
          <div class="cell-sub-color cell-sub-font">
            <div>{{timeString(data.updated)}}</div>
          </div>
        </div>
    </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'
// import AddressButton from './AddressButton.vue';
import BoardTitle from './BoardTitle.vue';

export default {
  components: { BoardTitle },  
  props:{
      id: String,
      data:Object,
      index: Number,
    },
    mounted() {
        // console.log('boardcell.data', this.data)
        // console.log('this.props.index', this.data.index)
    },
    data: function () {
      return {  
        //   name: value
      };
    },
    methods: {
      onLocation(data){
        console.log('onLocation', data)

        common.push({
            name: Const.component.LocalView,
            param: {
              idx: data.location_idx,
              name: data.name
            }
        })
      },
      click(){
          this.$emit('clicked', this.data, this.id);
      },
      timeString(t){
        return common.elapsedTime(t)
      }
    },      
}

</script>

<style scoped>
@import '@/assets/common';


.header {
  margin-bottom: 3px;
  color: darkgray;
  font-size: 14px;
}

.cell-container span {
  font-size: 20px;
  /* margin-right: 15px; */
}


.cell-container .hash {
  /* background: yellow; */
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.cell-container .like {
  /* background: yellow; */
  float: left;
  color: darkgray;
  margin-right: 4px;
}

#location { 
  margin-left: 5px;
  color: gray;  
  /* background: dodgerblue; */
  display: inline-block;
  
  /* border: 1px solid gray */
}

</style>

