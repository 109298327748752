<template>
  <div class="rating-container rounded border-cell margin">
    <div class="" >{{`${count}명이 호감을 가지고 있습니다.`}}</div>
    <div class="">{{'이 사용자에 대한 나의 호감도는?'}}</div>
    <div >
        <div class="">
            <span @click="onStar(0)" class="fa fa-star star-checked" ></span> 
            <span @click="onStar(1)" class="fa fa-star " v-bind:class="rate > 0 ? 'star-checked': 'star'"></span>
            <span @click="onStar(2)" class="fa fa-star " v-bind:class="rate > 1 ? 'star-checked': 'star'"></span>
        </div>
    </div>
    <div>{{comment}}</div>
    <div v-if="chnaged" class="">
        <div class="text-button-color" @click="onDoneRate">적용하기</div>
    </div>
  </div>
</template>

<script>
  import common from '@/common'
  import Const from '@/const'

  export default {
    components: {
    },
    setup() {
      console.log('board setup');
      return {
      }
    },
    props: {
    //   data: Object,
    },
    data(){ 
      return { 
        idx: 1,           
        rate: 0,
        count: 0,
        chnaged: false, // 평점 변경
      }
    },
    computed: {
        comment(){
            let str = ''
            switch (this.rate) {
                case 0:
                    str ='비호감(이 사용자의 글은 가려집니다.)'
                    break;
                case 1:
                    str ='보통(이 사용자의 글은 전체글에 노출됩니다.)'
                    break;
                case 2:
                    str ='호감(이 사용자의 글은 우선 노출됩니다.)'
                    break;
                default:
                    break;
            }
            return str;
        }
    },
    created: function () {        
    },
    mounted: function() {
          console.log('Memberview.mountend', this.data)      
    },
    methods:{   
        update(data){
            console.log('update', data)
           
            this.idx = data.idx;
            this.rate = data.rate
            this.count = data.count;
            this.persona_idx = data.persona_idx;
        },
        onStar(rate){
            // console.log('onStar', rate)
            this.rate = rate;
            this.chnaged = true;
        },
        onDoneRate(){ // 평점 완료
            const url = Const._api + "my/like/persona";
            const body = {
                idx: this.idx,
                rate: this.rate,
                persona_idx: this.persona_idx,
            }
            common.api_post(
                url, 
                body, 
                (data) => {
                    console.log(data);

                    const {error, msg} = data;

                    if(error > 0){
                        const param = {
                            header: '',
                            body: msg,
                        }
                        common.popupModal(param);
                        return;
                    }
                    this.idx = data.idx;

                    common.toast('등록완료');

                    this.chnaged = false

                    common.notifyEvent(Const.notify.like_persona, body)
                }
            ,this.modalErrorMessage
            )      
        },
    },
  }
</script>

<style >
@import '@/assets/common';
</style>
<style scoped>


.rating-persona-container {
    background: rgb(228, 228, 228);
    padding: 10px;
}
.rating-margin {
  margin: 10px 0 
}

.center-align {
  display: flex; 
  justify-content: center;
}

.star-checked {
  color: orange;
}
.star {
  color: gray;
}

</style>>

