<template>
    <div>
        <button type="button" class="btn font-size12 "
        :class="data.warning ? 'color-danger': 'text-button-color'"
        @click="data.onclick">
            {{data.title}}
        </button>
    </div>
</template>

<script>
// import common from '@/common';
// import Const from '@/const';
export default {  
  props:{
        data: Object,
    },
    mounted() {
        console.log('data', this.data)
    },
    data () {
      return {  
      };
    },
    methods: {
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.btn {
    font-weight: bold;
    padding: 10px 10px;
    border: none;
    cursor: pointer;
    width: 100%;
    /* border-radius: 20px; */
}

</style>
