<template>
    <div class="navbar-container bar-background-color border-top padding10">                
        <div v-for="(item, index) in copy_buttons" v-bind:key="index" class="navi-button" @click="item.onclick()">
            <!-- <span class="material-symbols-outlined ">share</span> -->
            <div v-html="item.html"></div>
        </div>
    </div>    
</template>

<script>
// import common from '@/common';
// import Const from '@/const';
export default {  
  props:{
        buttons: Array,
    },
    mounted() {
        // console.log('navbar.data', this.data)
        this.copy_buttons = this.buttons;
    },
    data () {
      return {  
        copy_buttons: [],
      };
    },
    methods: {
        update(index, html){
            this.copy_buttons[index].html = html;
        }
    },      
}
</script>

<style scoped>
@import '@/assets/common';


/* .navright {
  float: right;
} */

.navi-button {
  padding-right: 5px;
  padding-left: 5px;
  margin-top: auto;
  margin-bottom: auto;
}

.part-3 {
  width: 33%;
}

.left-elem {
    display: flex;
    justify-content: flex-start;
}

.center-elem {
  display:flex; 
  align-items: center;  
  justify-content: center;  
}

.right-elem{
    display: flex;
    justify-content: flex-end;
}

</style>
