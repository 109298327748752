<template>
    <div class="email-reg">
        <div v-if="email && email.length > 0 "><b>{{ email }}</b>로 새소식을 전달중입니다.</div>
        <div v-else class="between">
            <div>이메일로 새소식을 받을 수 있습니다.</div>
            <div class="color-text-button" @click="onEmail" >등록</div>
        </div>

    </div>
</template>

<script>
// import common from '@/common'
// import constVal from '@/const'

export default {  
    props:{
      email:String
    },
    data() {
      return {
      };
    },
    mounted() {
    },
    methods: {
        onEmail(){
            this.$emit('reg', this.data)
        },
        click(param){
            param;
            this.$emit('clicked', this.data)
        },
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.email-reg {
    font-size: 14px;
    background: white;
    padding: 10px;
}

</style>



