<template>    
    <div v-if="urls.length" 
        class="intro-back" 
        style="height: 100vh;"
    >
        <fetch-view ref="view" :urls="urls" />
    </div>
</template>

<style scoped>
@import '@/assets/common';
@import '@/assets/intro';


</style>

<script>
import FetchView from './FetchView.vue';

export default {  
    components: {
        FetchView,
    },  
    props:{
    },
    mounted() {
        // 필요하면 서버 주소 동적으로 처리
        var currentURL = window.location.href;

        // URL을 파싱하여 호스트 이름(포트 번호 제외) 가져오기
        var url = new URL(currentURL);
        var host = url.hostname;
        const protocol = process.env.NODE_ENV == 'production' ? 'https' : 'http'
        const port = process.env.NODE_ENV == 'production' ? '' : ':8001'

        this.urls = [
            `${protocol}://${host}${port}/html/intro/1.html`,
            `${protocol}://${host}${port}/html/intro/2.html`,
            `${protocol}://${host}${port}/html/intro/3.html`,
            `${protocol}://${host}${port}/html/intro/4.html`,
            `${protocol}://${host}${port}/html/intro/5.html`,
        ]
    },
  data() {
    return {  
        urls: [],
    };
  },
  methods: {
  },      
}
</script>
