<template>
    <div ref="container" style="height: 100vh; " 
        @scroll="handleScroll" 
        @touchstart="handleTouchStart" 
        @touchmove="handleTouchMove" 
        @touchend="handleTouchEnd"

        @wheel="handleWheel" 
    >
        <div class="content" ref="content" :style="{ margin: '0 auto',  width: width }">
            <div 
                v-for="(item, index) in rows" 
                v-bind:key="index" 
                :id="`Ad${index}`"            
                style="display: flex; width: 100%; height: 100vh; background: white;"
            >
                <div style="display: flex; flex-direction: column;  width: 100%; justify-content: space-between;" >
                    <div></div>
                    <div class="cell-background-color">
                        <!-- <div>
                            Hello
                        </div> -->
                        <slide-view 
                            :data="item" 
                            :ref="`slideview-${index}`"                         
                        />
                    </div>
                    <div class="buttons button-container max-width" 
                        style="padding-bottom: 46px;"
                    >
                        <div />
                        <div style="display: flex;">
                            <!-- <span v-if="(index - 1) >= 0" style="padding: 0 10px;" class="material-symbols-outlined" @click="onPre">arrow_upward</span> -->
                            <span v-if="rows.length > (index + 1)" style="padding: 0 10px;" @click="onNext" class="material-symbols-outlined" >arrow_downward</span>
                            <div v-else style="text-align: center;">
                                <span style="padding: 0 10px;" >더 이상 없습니다</span>
                                <email-info :email="user.email" v-on:reg="onEmail" />
                            </div>
                        </div>
                        <div />
                    </div>
                </div>
            </div>              
        </div>             
    </div>    
</template>

<script>

import axios from 'axios'
import common from '@/common';
import Const from '@/const'
import SlideView from './SlideView.vue';
import EmailInfo from './common/EmailInfo.vue'

let curPageTop = 0; // 현재 페이지의 top / 페이지가 변경될때 함께 변경된다. 

let startY = 0; 
let startX = 0;

let startOffsetTop = 0; // 마우스(터치) 무브 이벤트가 시작할때의 영역의 top 값

let lastY = 0;
let lastX = 0;

let _containerHeight = 0;

let curPage = 0;

let topMargin = 0;

let wheelSum = 0; // 휠로 이동한 누적값

let lastTimeStamp = 0;

export default {  
    components: {
        SlideView,
        EmailInfo,
    },
    props:{
        rows: Array,
        param: Object,
        width: String,
    },
    data: function () {
      return {  
        data: {},
        index: -1,
        callerIndex: undefined,
        next: null,
        swiper: null,        
        slideview: undefined,

        dir: 0, // 스크롤 방향, 위로1, 아래로 2
        prevScrollPosition: 0,
        currentScrollPosition: 0,
        intervalID: 0,        
      };
    },    
    mounted() {
        // console.log('this.param>', this.rows)
        // this.data = this.param.data;
        // this.request();

        // console.log('this.$refs.slideview', this.$refs)
        // this.slideview = this.$refs.slideview;

        {
            const comp = {
                onEvent: this.onEvent,
            }
            common.registEventHandler(comp);
        }

        this.prevScrollPosition = window.scrollY;

        this.request(0);
        this.request(1, false);

        this.updateScreenInfo();

        this.start(0);
    },
    updated(){
        // console.log('updated', this.rows.length);
        if(this.rows.length > 0){
            const id = `slideview-0`
            this.slideview = this.$refs[id][0];
            this.request(0);

            this.updateScreenInfo();
            // console.log('this.$refs.container.offsetTop', this.$refs.container.offsetTop)
        } 
    },
    methods: {
        onEmail(){
            common.popUp({
                name: Const.component.EmailForm,
                param: {}
            })
        },
        updateScreenInfo(){
            _containerHeight = this.$refs.container.offsetHeight
            topMargin = this.$refs.container.offsetTop;
            // console.log('containerHeight / topMargin', _containerHeight, topMargin)
        },
        ended(){            
        },
        updatePos(pos){
            this.$refs.content.style.top = pos + 'px';
        },
        handleWheel(event){      
            // console.log('handleWheel', event);

            const {timeStamp} = event;
            const gap = lastTimeStamp - timeStamp
            lastTimeStamp = timeStamp;

            if(gap > -100) return; // 연속된 이벤트는 무시

            let deltaY = event.deltaY; // 0보다 크면 화면의 아래부분이 올라오는거

            // clearTimeout(wheelTimer);

            wheelSum += deltaY

            const temp = curPageTop - wheelSum; 
            let newPosition = temp;

            // 화면 이동
            {
                if(temp > 30) // 화면 위
                {
                    newPosition = 30
                }
                else if( temp < -((this.rows.length - 1) * _containerHeight)) // 하단을 벗어난 경우
                {
                    // console.log('-((this.rows.length - 1) * _containerHeight) = ', -((this.rows.length - 1) * _containerHeight))
                    // console.log('temp', temp);
                    newPosition = -((this.rows.length - 1) * _containerHeight);
                }

                // console.log('newPosition>', newPosition)
                this.$refs.content.style.top = newPosition + 'px';
            }

            {
                const curY = this.$refs.content.offsetTop; // 현재
                if(deltaY > 0) // 페이지의 아래로 이동
                {
                    // console.log('페이지의 아래로 이동')
                    if(curPage + 1 == this.rows.length) {
                        common.moveSmooth(
                            curY, curPageTop + topMargin,
                            this.updatePos,this.ended,
                        )
                        return;
                    }

                    const page = curPage + 1; 
                    const distance =  page * _containerHeight;

                    const endValue = - distance + topMargin;
                    // console.log('endValue', endValue)

                    common.moveSmooth(
                        curY, endValue,
                        this.updatePos,this.ended,
                    )

                    curPageTop = endValue;
                    // console.log('curPageTop', curPageTop)
                    curPage = page;
                }
                else // 페이지의 위로 이동
                {
                    // console.log('페이지의 위로 이동')
                    if(curPage == 0){
                        common.moveSmooth(
                            curY, topMargin,
                            this.updatePos,this.ended,
                        )
                        return;
                    } 

                    const page = curPage - 1; // Math.floor(( Math.abs(newPosition) / _containerHeight ) )
                    const distance =  page * _containerHeight;
                    const endValue = - distance + topMargin;
                    common.moveSmooth(
                        curY, 
                        endValue,
                        this.updatePos,this.ended,
                    )

                    curPageTop = endValue;
                    // console.log('curPageTop', curPageTop)
                    curPage = page;
                }
                wheelSum = 0;

                this.onSlide(curPage)
            }
        },
        handleStart(x, y){
            startX = x;
            startY = y;
            lastY = startY;

            // console.log('handleTouchStart', startY)

            startOffsetTop = this.$refs.content.offsetTop            
        },       
        handleMove(x, y){
            lastX = x
            lastY = y

            const deltaX = lastX - startX;
            const deltaY = lastY - startY;

            if( Math.abs(deltaX) > Math.abs(deltaY)) return;

            const newPosition = startOffsetTop + deltaY; 
            this.$refs.content.style.top = newPosition + 'px';
        },         
        handleTouchStart(event){
            const touch = event.touches[0];

            this.handleStart(touch.clientX, touch.clientY)
        },        
        handleTouchMove(event){
            // console.log('handleTouchMove')

            const touch = event.touches[0];
            this.handleMove(touch.clientX, touch.clientY)
        },    
        handleTouchEnd()
        {
            const gapY = startY - lastY;
            const gapX = startX - lastX;

            // console.log('Math.abs(gapX), Math.abs(gapY)', Math.abs(gapX), Math.abs(gapY))
            if( Math.abs(gapX) > Math.abs(gapY)) return;

            if(Math.abs(gapY) > 10)
            {
                const curY = this.$refs.content.offsetTop; // 현재

                // console.log('pageTop / height / topMargin / height', curPageTop, containerHeight,  topMargin, containerHeight)
                if(gapY > 0) // 위로 스크롤
                {
                    if(curPage + 1 == this.rows.length) {
                        common.moveSmooth(
                            curY, 
                            curPageTop + topMargin,
                            this.updatePos,this.ended,
                        )
                        return;
                    }

                    common.moveSmooth(
                        curY, 
                        curPageTop - _containerHeight + topMargin,
                        this.updatePos,this.ended,
                    )

                    curPageTop -= _containerHeight;
                    curPage++;
                }
                else // 아래로 스크롤
                {
                    if(curPage == 0){
                        common.moveSmooth(
                            curY, 
                            topMargin,
                            this.updatePos,this.ended,
                        )

                        return;
                    } 
                
                    common.moveSmooth(
                        curY, 
                        curPageTop + _containerHeight + topMargin,
                        this.updatePos,this.ended,
                    )

                    curPageTop += _containerHeight;
                    curPage--;
                }

                this.onSlide(curPage)
            }
        },         
        start(index){
            if(this.rows.length == 0) return;

            const ad = this.rows[index];
            console.log('src>', ad)

            {
                const cb = (data) => {
                    console.log(data);
                    // if(data.error == 0)
                    // {                            
                    // }else
                    // {
                    // }
                }
                
                const url = `${Const._api}start`;
                console.log(url)
                axios.post(url, { ad} )
                .then(common.checkResponse(cb))
                .catch(error => {
                    console.log(error);
                });
            }

        },
        onSlide(index){
            console.log('슬라이드 선택', index)
            if(this.index == index) return;

            this.start(index);

            this.slideview?.resetSlide(); // 이전 것을 리셋하고
            
            this.index = index;
            this.request(index);        
            this.request(index + 1, false);

            const id = `slideview-${index}`
            // console.log('id', id)
            this.slideview = this.$refs[id][0];
            console.log('selected slide>', this.slideview.ad)
        },
        handleScroll(e){
            e
            // console.log('handleScroll')
            // e.preventDefault();
        },
        onEvent(name, param){
            // console.log('onEvent', name, param)

            switch (name) {
            case Const.notify.done:
                break;
            default:
                break;
            }

            this.param.onEvent(name, param);
        },        
        onMenu(){ // 본문메뉴
            // console.log('onMenu', this.user)
            const row = this.param.data

            let arr = [];
            if(this.user == null){
                // console.log(this.user)
            }
            else
            {
                if(this.user.idx == row.user_idx) // 자신이면
                { 
                    arr = [
                        // {
                        //   title : '여기에 메뉴 표시...',
                        //   cb: () => {
                        //     //   const obj = {
                        //     //     name: Const.component.DistForm,
                        //     //     param: {...this.data, chips: this.user.chips} 
                        //     //   }
                        //     //   common.popUp(obj);
                        //     console.log('메뉴선택')
                        //   },
                        // },
                        {
                            title : '공유',
                            cb: () => {
                                // this.popupShare();
                                const {idx, hash} = row;
                                common.popupShare(idx, hash)
                            }
                        },            
                    ]          
                }
                else{
                    // console.log('타인의 게시글')
                }

                arr.push(
                                        
                )

            }

            common.showMenu(arr)
        },
        onShow(){
        },
        request(index, start=true){
            // console.log('request', index)
            if(index + 1 > this.rows.length) {
                return
            }

            const src = this.rows[index];

            const id = `slideview-${index}`
            const sv = this.$refs[id][0];

            if(sv.end) {
                // console.log('request skip');
                return
            }

            if(src.images?.length > 0){
                // console.log('image loaded - skip');
                if(start) {
                    // console.log('start>', index)
                    sv.start()
                }

                return
            }

            // if(src.images?.length > 0)
            // {
            //     sv.start();
            //     return;
            // } 

            const url = `${Const._api}adv/${src.idx}/${src.hash}`
            // const url = `${host}/api/my/ad/${idx}`
            console.log(url)
            axios.defaults.withCredentials = true;
            axios.get(url).then(response => {
                const data = response.data;
                console.log(data)
                const {error, row, msg} = data;

                if(error > 0){
                    console.log(msg)
                    return;
                } 

                // src.htmls = row.htmls;
                row.expired = src.expired;
                row.remain_sec = src.remain_sec

                row.logined = row.needLogin != 'y';
                // console.log('row>', row)
                sv.update(row);             

                if(start) {
                    // console.log('start>', index)
                    sv.start()
                }
            })
            .catch(error => {
                console.error(error);
            });            
        },
        onChildEvent(param){
            console.log('onChildEvent', param)
        },
        onNext(){
            console.log('그냥 표시')
        },
        onPre(){
        },
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.swiper {
    margin: 10px;
    /* margin-top: auto;
    margin-bottom: auto; */
}

.bar-menu {
    margin-right: 0px;
    width: fit-content;
    padding: 10px;
}

.btn {
    margin: 20px;
}

.buttons {
    display: flex;    
    margin-right: auto;
    margin-left: auto;
    width: fit-content;
}

.button-container {
    /* position: fixed; */
    margin-top: 15px;
    width: 100%;

    display: flex; 
    justify-content: space-between; 
    width: 100%;
}

.menu-bar{
    display:flex; 
    justify-content: space-between; 
    background: white;
    position: sticky;
    top: 0;
}

.content {
    position: absolute;
    width: 100%;
    /* background-color: white; */

    /* transform: translateX(-50%); */
    /* transition: 0.5s ease;     */
}

</style>
