<template>
    <div class="navbar-container bar-background-color border-top padding10" style="z-index: 2;">
        <!-- left -->
        <div class="left-elem part-3 " >
          <div class="tb-btn navi-button" style="padding-left: 0px" 
            v-for="(item, index) in left" v-bind:key="index" @click="item.onClick" v-html="item.html"
          />
        </div>
        
        <!-- center -->
        <div class="center-elem part-3 middle">
          <div @click="onTitle">
            <div style="text-align: center;" v-html="title" ></div>
            <!-- 하단서브타이틀 -->
            <div class="flex color-gray " style="justify-content: center; margin-top : 2px">
              <div style="display:flex; color: gray; font-size:12px; " v-html="subTitle" />
            </div>
          </div>
        </div>

        <!-- 로그인한 경우 -->
        <div v-if="logined" class="right-elem part-3 navi-button">


          <!-- 사용자정의 된 경우 -->
          <div v-if="right" v-html="right.html" @click="right.onClick">
          </div>
          <!-- 사용자정의 없는 경우 -->
          <div v-else style="display:flex;">

            <!-- 알림영역 -->
            <div v-if="show_buttons" style="display:flex;">
              <!-- 관심채널 새글 -->
              <div v-if="is_noti" @click="onLikes" class="navi-button">
                <span class="material-symbols-outlined " :class="is_news_on ? 'color-on' : ''" >bookmark</span>
              </div>         

              <!-- 알림 -->
              <!-- 댓글 좋아요 등등 -->
              <div v-if="is_noti" @click="onNotice" class="navi-button">
                <!-- <i class="fa fa-fw fa-bell" :class="is_noti_on ? 'color-on' : 'button-color'" ></i>  -->
                <span class="material-symbols-outlined" :class="is_noti_on ? 'color-on' : ''">favorite</span>
              </div>         

              <!-- 메시지 -->
              <!-- 1:1대화 -->
              <div v-if="is_message" @click="onMessage" class="navi-button ">
                <span class="material-symbols-outlined" :class="is_message_on ? 'color-on' : ''">mail</span>
              </div> 
            </div>

            <!-- 더보기버튼 -->
            <div v-show="show_more" class="navi-button" >
              <i class="fa fa-bars " @click="onMore"></i>
            </div>
            
          </div>

          <!-- 더보기 버튼 -->

          <!-- 사용자정의 -->
          
        </div>

        <!-- 로그인 안한 경우 -->
        <div v-else class="right-elem part-3 ">
          <!-- 로그인 -->
          <div v-show="show_login" @click="doLogin" class="navi-button">
            <div class="">가입 | 로그인</div>
          </div>         

          <!-- 빈값 -->
          <div v-if="logined && show_buttons == false" class="right-elem part-3 ">

          <!-- 사용자정의 -->

          </div>

        </div>
    </div>    
</template>

<script>
import common from '@/common';
import Const from '@/const';
// import ServiceName from '@/components/common/ServiceName'

export default {  
  components: {
    // ServiceName,
  },  
  props:{
      data:Object,
      container: Object,
      title: String,

      show_more: {
          type: Boolean,
          default: false,
      },
      show_login: {
        type: Boolean,
        default: true,   // 로그인 버튼 보이기      
      },
      right: {
        type: Object,
        default: null,
      },
      onMore: {
        type: Function,
        default: () => {console.log('more...')}
      },
      onTitle: {
        type: Function,
        default: () => {console.log('ontitle...')}
      },
    },
    data () {
      return {  
        left: [],
        subTitle: '',
        is_menu: true,
        is_share: false,

        is_search: false,

        is_noti: false,
        is_message: false,
        is_bookmark: false,

        is_news_on: false,   // 관심채널의 새소식이 있는가?
        is_noti_on: false,          
        is_message_on: false,

        bookmark: 'bookmark-off',
        title_proc: null,
        logined: false,

        show_buttons: true, // 알림 버튼 보이기        

      };
    },
    mounted() {
        console.log('TitleBarEx', this.data)
        this.left = this.data?.left;

        // this.right = {
        //   html: '<i class="fa fa-bars"></i>',
        //   onClick: this.onNotice
        // }

        if(this.data)
        {
          this.show_buttons = (this.data.show_buttons == undefined) ? true : this.data.show_buttons; // 디폴트 true
        }

        // 디폴트 백키
        if(this.left == null){
          this.left = [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
            {
              html: '<span class="material-symbols-outlined " style="padding-left:5px">home</span>',
              onClick: this.container.home,
            },
          ]
        } 
    },
    methods: {  
      onLikes(){
        console.log('onLikes')

        common.push({
          param: {type: 'like'},
          name: Const.component.SavedView, // 'NoticeView'
        })
      },
      onMyChannels(){
        this.is_news_on = false;
        this.session.user.new_post = false;

        common.push({
          name: Const.component.MyChannelView, // 'NoticeView'
        })
      },
      onNotice(){
        this.is_noti_on = false;
        this.session.user.notice = false;

        common.push({
          name: Const.component.NoticeView, // 'NoticeView'
        })
      },
      onMessage(){
        this.is_message_on = false;
        this.session.user.message = false;

        common.push({
          name: Const.component.MessagesView //'MessagesView'
        })
      },
      click:function(button){
        // console.log('appbar.click : ', button)    
        this.$emit('clicked', button)
      },
      doLogin(){
        common.popupLogin()
      },
      didLogin(show_state = true){
        console.log('didLogin')
        this.is_noti = true;
        this.is_message = true;

        this.logined = true;
        this.show_buttons = show_state;
      },
      updateButtonState(){
        console.log('updateButtonState', this.session)
        if(this.session.user){
          this.is_noti_on = this.session.user.notice;
          this.is_message_on = this.session.user.message;
          this.is_news_on = this.session.user.new_post;
        }
      }
    },      
}
</script>

<style scoped>
@import '@/assets/common';


/* .navright {
  float: right;
} */

.part-3 {
  width: 33%;
}

.left-elem {
    display: flex;
    justify-content: flex-start;
}

.center-elem {
  display:flex; 
  align-items: center;  
  justify-content: center;  
}

.right-elem{
    display: flex;
    justify-content: flex-end;
}

</style>
