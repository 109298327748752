<template>
    <div class="snackbar" v-bind:class="is_show ? 'show' : ''">{{message}}</div>
</template>

<style scoped>
@import '@/assets/common';
/* The snackbar - position it at the bottom and in the middle of the screen */


/* Show the snackbar when clicking on a button (class added with JavaScript) */
.snackbar.show {
  visibility: visible; /* Show the snackbar */
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar. 
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: snackbar-fadein 0.5s, snackbar-fadeout 0.5s 2.5s;
  animation: snackbar-fadein 0.5s, snackbar-fadeout 0.5s 2.5s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes snackbar-fadein {
  from {bottom: 0; opacity: 0;} 
  to {bottom: 30px; opacity: 1;}
}

@keyframes snackbar-fadein {
  from {bottom: 0; opacity: 0;}
  to {bottom: 30px; opacity: 1;}
}

@-webkit-keyframes snackbar-fadeout {
  from {bottom: 30px; opacity: 1;} 
  to {bottom: 0; opacity: 0;}
}

@keyframes snackbar-fadeout {
  from {bottom: 30px; opacity: 1;}
  to {bottom: 0; opacity: 0;}
}
  /*  */
</style>

<script>
export default {  
    props:{
      data:Array,
      id: String,
    },
    mounted() {
        // console.log('data', this.data)
    },
    data: function () {
      return {  
        //   name: value
        message: '',
        is_show: false,
      };
    },
    methods: {
      // click:function(data, n){
      // },
      show: function(message){
        // console.log('show');
        this.message = message;
        this.is_show = true;

        const b = this;
        setTimeout(function(){ 
          b.is_show = false; 
        }, 3000);
      },
      hide: function(){        
        this.is_show = false;
      }
    },      
}

</script>

