<template>
    <div class="between cell-background-color padding10" >
      <div class="flex">
        <!-- <div class="location" >{{address_str}}</div> -->
        <AddressButton :data="{name: address_str}" />
        <div class="border text-button-color text-button-size-small" style="margin-left: 10px ;" @click="search">현재위치로</div>
        <!-- <button class="btn location-btn" @click="search"><i class="fa fa-crosshairs"></i></button> -->
      </div>
      <div v-show="found" class="border text-button-color text-button-size-small" @click="onLocation">{{address_str}} 둘러보기</div>
    </div>
</template>

<style scoped>
@import '@/assets/common';

.margin {
  margin: 10px 10px;
  padding: 10px 10px;
  background: rgb(233, 233, 233);
}
</style>

<script>
import common from '@/common'
import Const from '@/const'
import AddressButton from './AddressButton.vue';

export default {
  components: { AddressButton },  
    props:{
    },
    mounted() {      
      // do nothing
    },
    data: function () {
      return {  
        address: null,
        address_str: '알 수 없슴',
        found: false, // 위치
        location_idx: 0,
        ready: true,
      };
    },
    methods: {
      onLocation(){
        console.log('onLocation', this.location_idx)
        if(this.location_idx == 0) return;

        common.push({
            name: Const.component.LocalView,
            param: {
              idx: this.location_idx,
              name: this.address_str
            }
        })
      },
      search:function(){
        this.address_str = '위치 가져오는 중...'
        this.found = false;
        common.getLocation(this.didGetLocation);
      },
      didGetLocation(location){
        console.log('didGetLocation', location)
        // 로컬에 저장
        const {latitude, longitude} = location.coords;

        this.sendPosition({latitude, longitude})
      },
      sendPosition(position) {
        console.log('sendPosition', position);

        const {latitude, longitude} = position;

        // 서버에 위치 전송 후 주소 받기
        {
          common.api_post(Const._api + "location", position, (data) => {
            console.log('location>', data);
            const {error, address, msg, location_idx} = data;

            if(error > 0){
              console.log('error')
              this.$emit('failed', msg)
            }
            else
            {
              this.location_idx = location_idx;
              this.address = address;
              // const {region_1depth_name, region_2depth_name, region_3depth_name} = address;
              const {region_3depth_name} = address;
              this.address_str = region_3depth_name;
              this.found = true;
              this.$emit('success', address, location_idx)

              common.saveLocation({latitude, longitude, location_idx}, 
                () => {
                  common.toast(Const.message.saved_location)
                }
              )
            }
          })
        }
      },
      movePosition(position){
        console.log('movePosition', position)
        const {location_idx} = position;
        this.location_idx = location_idx;

        const param = { location_idx }

        common.api_post(
          Const._api + "location/move", 
          param, 
          (data) => {
            const {error, address, msg} = data;
            
            if(error > 0){
              console.log('error')
              this.$emit('failed', msg)
            }
            else
            {
              this.address = address;
              // const {region_1depth_name, region_2depth_name, region_3depth_name} = address;
              // this.address_str = `${region_1depth_name}>${region_2depth_name}>${region_3depth_name}`

              const {region_3depth_name} = address;
              this.address_str = region_3depth_name;
              this.found = true;
              
              this.$emit('success', address, location_idx)

              common.saveLocation({location_idx})
            }
          }
        )
      },
      moveSavedLocation(){
        console.log('moveSavedLocation')
        const location = common.loadLocation();
        if(location) this.movePosition(location)
      },
    },
}

</script>

