<template>
    <div class="cell-container margin-side" >
        <div class="center-h">
            <pre style="text-align: center; line-height: 1.5;">{{ info }}</pre>
        </div>
        <div class="center-h">
            <text-button style="width: fit-content;" :data="{title: button_title, onclick: onclick}" />
        </div>
    </div>
</template>

<script>
import TextButton from './TextButton.vue';
// import common from '@/common'
// import constVal from '@/const'

export default {
  components: { TextButton },  
    props:{
      info:String,
      onclick:Function,
      button_title:String,
    },
    data() {
      return {
        // value: null,  
      };
    },
    mounted() {
        // this.value = this.data.value;
    },
    methods: {
        // onEnter(){
        //     console.log('onEnter')
        //     this.$emit('entered', this.value)            
        // },
        // onChange(){
        //     this.$emit('changed', this.value)            
        // },
        // click(param){
        //     param;
        //     this.$emit('clicked', this.data)
        // },
    },      
}
</script>

<style scoped>
@import '@/assets/common';


</style>



