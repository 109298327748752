<template>
      <div class="search-bar search-bar-color between">
        <div class="flex">
            <button class="btn" @click="onBack"><i class="fa fa-chevron-left"></i></button>
            <div style="margin-left:10px;" v-html="title" />
        </div>
        <div style="width:fit-content; text-align:right;">
          <input type="search" class="searchpop-input" v-model="input_text" @keypress.enter="doSearch"  placeholder="" name="search">
          <button class="btn" @click.stop="doSearch" ><i class="fa fa-search"></i></button>            
        </div>
      </div>
</template>

<script>
export default {  
    props:{
      data:Object,
    },
    mounted() {
        // console.log('data', this.data)
        if(this.data){
          this.title = this.data.title;

          this.left = this.data.left;

          if(this.data.left == null) this.left = '<i class="fa fa-chevron-left"></i>';

          this.right = this.data.right;
          if(!this.data.right) this.right = '<i class="fa fa-bars"></i>';
        }
    },
    data: function () {
      return {  
        input_text: '',
        title: '',
        left:'',
        right:'',
      };
    },
    methods: {
      doSearch(){
        this.$emit('search', this.input_text)
      },
      onclick(menu){
        this.$emit(menu, this.data)
      },
      onBack(){
        this.$emit('back', this.data)
      },
      setMutiLineTitle(arr){
        this.title = `
        <div>
        <div>${arr[0]}</div>
        <div style="color: gray; font-size:12px">${arr[1]}</div>
        </div>
        `
      }
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.searchpop-input {
    width: 200px;
}


</style>

