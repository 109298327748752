<template>
  <div style="background: white;">

    <div class=" background-color">
      <search-bar ref="searchbar" v-on:back="onBack" v-on:search="onSearch" />

      <TabMenu class="" :data="[around_board, world_board]" v-on:clicked="onTabMenu"></TabMenu>
      <div class="comment">{{comment}}</div>

      <location-bar ref="locationBar" v-show="selectedTab == around_board" v-on:success="didGetAddress" v-on:failed="onFailedAddress" />

      <!-- 위치게시판 -->
      <div class=" margin-top" v-show="selectedTab == around_board" v-on:scroll.passive="handleScroll" >

        <lets-write 
          v-if="around.not_found"
          v-on:clicked="doMake(1)" 
          :text="`'${address.region_3depth_name}' 사람들과 이야기하는\n '${search_text}' ${name5} 없습니다.\n${name2} 만들고 가장 먼저 글을 남겨보세요.`"
        />

        <div v-for="(item, index) in around.exact" v-bind:key="index">
          <boardcell
            :data="item" 
            v-on:clicked="onBoard" />
        </div>        
        <div class="margin-top" style="font-size:14px; margin-top:10px" v-if="around.similar.length > 0" >그밖의 채널</div>
        <div v-for="(item, index) in around.similar" v-bind:key="index">
          <boardcell
            :data="item" 
            v-on:clicked="onBoard" />
        </div>
      </div>

      <!-- 세상게시판 -->
      <div class="margin-top" v-show="selectedTab == world_board" v-on:scroll.passive="handleScroll" >

          <lets-write 
            v-if="world.not_found"
            v-on:clicked="doMake(0)" 
            :text="`모두가 함께 이야기하는\n '${search_text}' 채널이 없습니다.\n채널을 만들고 가장 먼저 글을 남겨보세요.`"
          />

          <!-- <div v-if="world.not_found" class="not-found">
            <div>
              <div class="between" style="margin-top:10px">
                <div /><div>'{{search_text}}' 채널이 없습니다.</div><div />
              </div>

              <div class="between" style="margin-top:10px">
                <div /><div>채널을 만들고 가장 먼저 글을 남겨보세요.</div><div />
              </div>
            </div>
            <div class="between">
              <div /><button class="mkbtn" @click="doMake(0)">글쓰기</button><div />
            </div>
          </div> -->
          
          <div v-for="(item, index) in world.exact" v-bind:key="index">
            <boardcell         
              :data="item" 
              v-on:clicked="onBoard" />
          </div>
          <!-- <div v-if="world.similar.length > 0"  >비슷한 채널</div> -->
          <div class="margin-top" style="font-size:14px; margin-top:10px" v-if="world.similar.length > 0" >그밖의 채널</div>
          <div v-for="(item, index) in world.similar" v-bind:key="index">
            <boardcell         
              :data="item" 
              v-on:clicked="onBoard" />
          </div>
      </div>

      <div class="tail"></div>
    </div>
  </div>
</template>

<script>
  import common from '@/common'
  import Const from '@/const'
  import TabMenu from '@/components/common/TabMenu.vue'
  import boardcell from '@/components/common/BoardCell.vue'
  // import swiper module styles
  import 'swiper/css'
  import 'swiper/css/pagination'
  import constVal from '@/const'
  import LocationBar from '@/components/common/LocationBar.vue'
  import SearchBar from '@/components/SearchBar.vue'
  import LetsWrite from './common/LetsWrite.vue'
  
  // more module style...

  
  export default {
    components: {
      TabMenu,
      boardcell,
      LocationBar,
      SearchBar,
      LetsWrite,
    },
    props: {
      param: Object
    },
    setup() {
      console.log('setup');
      return {        
      }
    },
    data() { 
      return  {
        name: Const.brand.name,
        name2: Const.brand.name2,
        name3: Const.brand.name3,
        name4: Const.brand.name4,
        name5: Const.brand.name5,

        is_show: false,
        address: '',
        world_board: Const.world_board,
        around_board: Const.around_board,
        selectedTab: Const.around_board,
        input_text: '',
        search_text: '',
        word: '',
        boards: [],
        is_result: false,
        world: {
          not_found: false,
          exact: [],
          similar: [
          ],
        },
        around: {
          not_found: false,
          exact: [],
          similar: [
          ],
        },
      }
    },
    created: function () {
      // console.log('created')
    },
    mounted: function() {   
      // console.log('Search.mounted');
      common.checkSession(this.isLogin)
    },        
    computed: {
      comment(){
        return this.selectedTab == this.world_board ? 
        '모두가 함께 이야기하는 채널입니다' : 
        '동네 사람들과 함께 이야기하는 채널입니다'
      }
    },
    methods:{      
      handleScroll(e){
        console.log('handleScroll',e);
      },
      showPopup(show){
        console.log('showPopup', show);
        this.is_show = show;
        this.$refs.searchpop.is_show = show;
      },
      isLogin(isLogin, user){
        console.log('isLogin', isLogin, user);

        this.$refs.locationBar.moveSavedLocation();

        this.input_text = this.param.text;
        this.doSearch();            
      },
      onSearch(param){
        console.log('onSearch', param)
        if(param.isTrusted) return; // 두번 호출되네... ????? 임시로 막았다.

        this.input_text = param
        this.doSearch();
      },
      onSearchPop(tag, param){
        console.log('onSearchPop', tag, param);

        switch (tag) {
          case 'search':
            console.log('onSearch', param);
            this.input_text = param;
            this.doSearch();            
            break;
          case 'close':
            this.is_show = false;            
            break;
        
          default:
            break;
        }
      },
      onBack(){        
        common.back();
      },
      onFailedAddress(msg){
        console.log('onFailedAddress', msg);
        this.showModal('오류발생', msg)
      },
      showModal: function(header, body){            
        const param = {
            header,
            body,
            // callback
        }
        common.popupModal(param);

      },      
      onTabMenu(param){
        console.log('onTabMenu', param);
        this.selectedTab = param;
        switch (param) {
          case this.world_board:
            break;
          case this.around_board:
            // console.log(this);
            break;        
          default:
            break;
        }
      },
      search: function(){
        console.log('search');

        const url = constVal._api + "board/search/all";
        const body = {
          text: this.search_text,
        }

        common.api_post(url, body, data => {
            console.log('search>', data)

            const {world, around} = data;

            this.world = world; // rows 
            this.world.not_found = false;
            if(world.exact.length == 0){
              this.world.not_found = true;
            } 

            console.log('around', around);
            if(around.error == 1) // 위치정보가 없슴
            {
              // 화면에 안내 표시
              common.toast('현재 위치를 알 수 없습니다.');

              // 재시도
              // this.$refs.locationBar.search()
            }
            else // if(around.error == 0)
            {
              around.exact.forEach(element => {
                const temp = element.name.split('@');
                element.location = temp[1];
                element.name= temp[0];
                element.address = element.location.split('>')[2];
              });            

              around.similar.forEach(element => {
                const temp = element.name.split('@');
                element.location = temp[1];
                element.name= temp[0];
                element.address = element.location.split('>')[2];
              });            

              console.log('this.around', this.around);

              this.around = around;  // 근처 게시판 : rows(목록), error: 위치정보 정보없슴(1)

              console.log('around.exact', around.exact);
              this.around.not_found = false;            
              if(around.exact && around.exact.length == 0){
                this.around.not_found = true;
              }               
            }
        })
      },
      didGetAddress(address, position){
        console.log('didGetAddress', address, position)

        this.address = address;
        this.search()
      },
      sendPosition:function(position) // 위도,경도 보내기
      {
        console.log('sendPosition', position);
        const {latitude, longitude} = position.coords;
        const params = {latitude, longitude};

        common.api_post(constVal._api + "location", params, (data) => {
          console.log('location', data);
          const {error, msg, address} = data;
          if(error > 0){
            this.showModal('오류발생', msg)
          }else{
            // const {region_1depth_name, region_2depth_name, region_3depth_name} = address;
            // this.address = `${region_1depth_name}>${region_2depth_name}>${region_3depth_name}`
            this.address = address;

            common.toast('위치가 확인되었습니다');
            
            this.doSearch(); // 검색
          }
        })
      },
      onBoard: function(item){
        console.log('onBoard', item)

        common.push({
          name: Const.component.SavedView,
          param: {idx: item.idx},
        })
      },      
      doSearch(){
        this.search_text = this.input_text.trim();
        this.input_text = this.search_text;
        console.log(`doSearch>${this.search_text}<`);

        if(this.search_text.length > 0){
          this.search();
        }
      },
      doMake(around){
        console.log('doMake', this.search_text, around);
        
        const url = constVal._api + "board/make";
        const body = {
          text: this.search_text,
          around
        }

        common.api_post(url, body, data => {
          // console.log(data);
          const {error, msg} = data;
          if(error > 0){
            common.toast(msg)            
          }
          else{
            around ? this.around.not_found = false : this.world.not_found = false;

            const {board} = data.row;

            common.push({
              name: Const.component.SavedView,
              param: {
                idx: board.idx,
                write: true,
              } 
            })
          }
        })        
      },
    },    
  }
</script>

<style scoped>
@import '@/assets/common';

.top-container {
    position: absolute;
    top: 0;    
    height: 100vh;
    width: 100%;
    background: white;

    /* display: flex;        
    flex-direction: column; */
}

.tail {
    width: 100%; 
    height: 80px; 
    /* background: red; */
}

</style>

