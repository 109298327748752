<template>
  <div class="cell-container" @click="click(data)">    
    <div style="display: flex;">
      <div v-if="data.pickable" class=" color-primary cherry-font" style="display: flex; height: fit-content; margin-top: auto;margin-bottom: auto;">
        <logo-image :size="'15px'" />
        <div style="font-size: 20px; margin-left: 2px;">{{data.reward}}</div>
      </div>
      <div style="margin: auto 10px;">{{ data.title }}</div>
    </div>
    <div v-if="data.pickable" class="time" style="text-align: right; margin: 5px 0 0 0;">
      <div>
        <!-- {{ expired }} 까지 확인하세요 -->
        {{ remain }} 남음
      </div>
    </div>
  </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'
import LogoImage from './LogoImage.vue';
// import BoardTitle from './BoardTitle.vue';

export default {  
    components: {
        LogoImage 
      // BoardTitle 
    },  
    props:{
      data:Object,
    },
    mounted() {
      // console.log('SlideCell', this.data)
    },
    computed: {
      type(){
        return Const.reportType[this.data.type];
      },
      created(){
        return common.timeString(this.data.created, `{mon}.{day} {hour}:{min}`);
      },
      expired(){
        return common.timeString(this.data.expired, `{mon}.{day} {hour}:{min}`);
      },
      remain(){
        const hour = Math.floor(this.data.remain_sec / 60 / 60);
        const min = Math.floor(this.data.remain_sec / 60 - hour * 60);
        // console.log('hour, min>', hour, min)
        
        if(hour > 1) return `약 ${hour}시간 ${min}분`

        if(min > 1) return `약 ${min} 분`

        return `${this.data.remain_sec}초`
      }
    },
    data: function () {
      return {  
      };
    },
    methods: {
      onShow(){
        this.$emit('show', this.data.idx, this.data.hidden, this)
      },
      click:function(data){
          // console.log('postcell.click : ', data)    
          this.$emit('clicked', data)
      },
      manage(type){
        // console.log('manage', type)
        this.$emit('manage', type, this.data, this)
      },
      timeString(t){
        return common.elapsedTime(t);
      },
      test(){
        console.log('hello')
      }      
    },      
}

</script>

<style scoped>
@import '@/assets/common';

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cell-container{
  padding: 10px;
  width: 100%;
}


</style>

