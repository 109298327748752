<template>
    <div class="cell-container">
        <a :id="data.idx"></a>

        <!-- 상단 -->
        <div class="between margin-bottom">
            <!-- 작성자정보 -->
            <div style="display: flex;">
                <div v-if="data.writer" class="badge writer margin-right">작성자</div>                
                <div v-if="data.me" class="badge mine">내글</div>
                <div v-else-if="data.nick" class="nick badge">{{data.nick}} </div>
                <div class="cell-info" > {{created}}</div>
            </div>
            <!-- 메뉴 -->
            <div class="right">
                <div class="cell-button" @click="click('menu')">&hellip;</div>
            </div>
        </div>

        <!-- <div v-if="data.p_idx > 0" class="mention">{{data.mention}}에게 답장</div> -->

        <!-- 멘션 -->
        <div v-if="data.mention" class="padding10 light-gray margin-bottom text-hint ">
            <div @click="onShowMention" >
                <div v-if="data.mention.nick">이 댓글에 대해</div>
                <div v-else-if="data.persona && data.persona.idx === data.mention.persona_idx" >내글</div>
                <!-- <div v-else>{{`<${data.mention.persona_idx}>`}}의 작성글</div> -->
                <div v-else>이 댓글에 대해</div>
                <div class="one-line" v-show="show_mention == false">{{data.mention.text}}</div>
            </div>
            <div v-show="show_mention" >
                <div  class="reply-body">{{data.mention.text}} </div>            
                <a :href="`#${data.p_idx}`" style="text-align:right;"><div class="bold">이동하기</div></a>
            </div>
        </div>

        <!-- 본문 -->
        <div class="margin-top10" @click="click('body')">
            <img @click="click('image', data)" style="margin-right: 5px" v-if="data.image && data.image.length > 0" v-bind:src="data.url">
            <!-- <div class="reply-body" >{{content}}</div> -->
            <auto-link style="font-size: 14px;" :data="data.text" />
        </div>

        <div class="between margin-top10">
            <div class="left ">
                <div v-if="data.bottomLeft" class="cell-sub-color cell-sub-font bold" @click="data.bottomLeft.handler">{{data.bottomLeft.title}}</div>
                <!-- <div v-else class="cell-sub-color cell-sub-font bold-400 black" @click="onLike">좋아요 {{data.like}}</div> -->
                <i v-else class="fa margin-right font-size12" :class="isLike > 0 ? ['fa-heart', 'color-like'] : 'fa-heart-o'" aria-hidden="true" @click="onLike"></i>
            </div>

            <div class="right"> 
                <div v-if="data.bottomRight" class="cell-sub-color cell-sub-font bold" @click="data.bottomRight.handler">{{data.bottomRight.title}}</div>
                <div v-else class="cell-sub-color cell-sub-font bold-400 black" @click="click('reply')">답글쓰기</div>
            </div>
        </div>
    </div>    
</template>

<script>
import common from '@/common'
import Const from '@/const'
import AutoLink from '@/components/common/AutoLink.vue'

export default ({
    components: {
      AutoLink,
    },    
    setup() {
        
    },
    props:{
      data:Object,
    },
    mounted() {
        console.log('data', this.data)
        this.content = common.handleLink(this.data.text)
        this.isLike = this.data.isLike;
    },
    data: function () {
      return {  
        //   name: value
        show_mention: false,
        content: '',
        isLike: 0,
      };
    },
    computed: {
        created(){
            return common.elapsedTime(this.data.created);
        }
    },
    methods: {    
        onShowMention(){
            this.show_mention = !this.show_mention;
        },
        click(tag){
            this.$emit('clicked', tag, this.data);
        },
        onLike(){
            const url = Const._api + "reply/like";
            const param = {
                idx: this.data.idx,
                like: !(this.isLike),
            }
            common.api_post(url, param, data => {
                // console.log('data', data)

                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    // console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }
                // this.$emit('done');
                this.isLike = !(this.isLike) ? 1 : 0;

                // this.$refs.navbar.bookmark = this.board.isLike ? "bookmark-on" : "bookmark-off" ;
            })
        },
        reply:function(){
            this.$emit('reply');
        },
    },  
})
</script>
<style scoped>
@import '@/assets/common';

.mention {
    font-size: 14px;
    background: #f1f1f1;;
}

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

#reply-form {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: auto;
}

#reply-form .flex-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: stretch;
  background-color: #f1f1f1;
}

#reply-form .image {
    height: 80px;
    background-color: pink;
}

#reply-form .thumbnail {
    width: 90px; height: 90px;
}

#reply-form .image > span{
    position: absolute; 
    top:-4px;  
    right: -4px;
    position: absolute;
    padding: 5px 10px;
    border-radius: 50%;
    background-color: black;
    color: white;
}
</style>

