<template>
    <div class="content-body" v-html="_content" ></div>
</template>

<script>
export default {  
    props:{
      data:String,
    },
    computed: {
        _content(){
            // console.log("computed", this.data)
            if(this.data) {
                this.setContents(this.data);                
            } 
            return this.content;
        }
    },
    mounted() {
        // console.log('mounted')
        if(this.data) this.setContents(this.data);
    },
    data: () => {
      return {  
        content: '',
      };
    },
    methods: {        
    //   click:function(data, n){
    //       this.selected = n;
    //       this.$emit('clicked', data, this.id)
    //   },
        setContents(src){
            let stripped = src.replace(/</g, '&lt');
            stripped = stripped.replace(/>/g,"&gt;"); 

            let result = '';
            const lines = stripped.split('\n');
            lines.forEach(line => {
            // < with &lt; and > with &gt;
            let after = '';
            const array = line.split(' ');
            array.forEach(element => {
                if( element.startsWith('http://') || element.startsWith('https://'))
                {
                const temp = `<a href='${element}' target='_blank'>${element}</a>`

                after += ' ' + temp;
                }
                else{
                after += ' ' + element
                }
            });

            result += after + '\n';
            });
            this.content = result    
        }
    },      
}
</script>

<style scoped>
@import '@/assets/common';

.content-body {
    white-space: pre-wrap;   
    word-break:break-all;
}

</style>