<template>
    <div class="tabmenu border-bottom">
        <button class="tab-button" 
          :style="{width: 100/menus.length + '%'}" 
          v-bind:class="[selected == index ? 'active' : '', on[index] ? 'on' : '']" 
          v-for="(item, index) in menus" @click="click(item, index)" v-bind:key="index"
          >
          {{item}}          
        </button>
    </div>
</template>

<style scoped>
@import '@/assets/common';

.on {
  color: dodgerblue;
  font-weight: bold;
}

</style>

<script>
export default {  
    props:{
      data:Array,
      id: String,
    },
    mounted() {
        this.menus = this.data;
        if(this.menus) this.on = new Array(this.menus.length)
    },
    data: function () {
      return {  
        //   name: value
        menus: [],
        selected: 0,
        on: []
      };
    },
    methods: {
      menu(arr){
        // console.log('this.data', this.data);
        this.menus = arr;
        this.on = new Array(this.menus.length)
      },
      click(data, n){
          this.selected = n;
          // console.log('tabmenu.click : ', data)    
          this.$emit('clicked', data, this.id)
      },
      updateOn(index, on){
        this.on[index] = on;
      }
    },  
}

</script>

