<template>
  <div class="form-container">
      <!-- <h1>{{data.title}}</h1> -->
      <div class="margin-top"></div>  
      <label for="email" class="margin-bottom">{{param.comment}}</label>
      <input v-model="text" type="text" ref="input" :placeholder="param.placeholder" @keypress.enter="done" required>
    
      <button type="button" class="btn" @click="done">확인</button>
      <button type="button" class="btn cancel" @click="close()">닫기</button>
  </div>
</template>

<script>
// import common from '@/common';
// import Const from '@/const'

export default { 
  components: {
  }, 
  props:{
    param:Object,
  },
  mounted() {
      // console.log('data', this.data)
      this.text = this.param.text;
      this.$refs.input.focus();
  },
  data: function () {
    return {  
      //   name: value
      text: '',
      message:'',
      is_show: false,
    };
  },
  methods: {
    done: function(){
      this.text = this.text.trim();
      if(this.text.length == 0){
        return;
      }
      // this.$emit('clicked', 'done', this.text);
      // common.notifyEvent(Const.notify.nickname, this.text)
      this.param.cb(this.text);
      this.close();
    },
    close: function(){
      this.text = '';
      // this.is_show = false;
      this.param.controller.closePopup();
    },
    show(){
      this.is_show = true;
    },
  },      
}

</script>

<style scoped>
@import '@/assets/common';
.margin-top{
  height: 30%;
}
.open-button {
  /* background-color: #555; */
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  /* position: fixed; */
  bottom: 23px;
  right: 28px;
  width: 280px;
}
  /* Full-width input fields */
.form-container input[type=text], .form-container input[type=password] {
  width: 100%;
  /* padding: 15px; */
  padding: 15px 0 15px 0;
  margin: 20px 0;
  border: none;
  background: #f1f1f1;
}
  
/* When the inputs get focus, do something */
.form-container input[type=text]:focus, .form-container input[type=password]:focus {
  background-color: #ddd;
  outline: none;
  border: none;
}

/* Add a red background color to the cancel button */
.form-container .cancel {
  color: black;
  /* background-color: whitesmoke; */
  /* border: solid 0.5px black; */
  border-radius: 20px;
}

</style>

