<template>
    <div style="display: flex; align-items: center;">
        <img src="/cherry.svg" :style="style" />
        <div class="color-primary cherry-font" :style="text_style" >{{name}}</div>
    </div>    

</template>

<script>
// import common from '@/common'
import Const from '@/const'

export default {
  components: {  },  
    props:{
      size: Number,
    },
    data() {
      return {
        name: Const.name,
        text_style: {
            'font-size': `${this.size * 0.8}px`,
            'margin-left': '5px',
        },
        style: {
        }
      };
    },
    mounted() {
        console.log('mounted', this.size)
        this.style.height = `${this.size}px`;
        this.style.width = `${this.size}px`;

        // this.style.fill = 'blue';
    },
    methods: {
    },      
}
</script>

<style scoped>
@import '@/assets/common';


</style>



