<template>
  <div class="flex" id="container" @click.stop="click">
    <div><i class="fa fa-map-marker font-size15" aria-hidden="true"></i></div>
    <div class="font-size15" id="name">{{data.name}}</div>
  </div>
</template>

<style scoped>
@import '@/assets/common';

#container {
  /* background: lightgray; */
  /* border: solid gray */
}

#container > div {
    color: gray
}

#name {
    margin-left: 2px;
}

</style>

<script>
// import common from '@/common'
// import constVal from '@/const'

export default {  
    props:{
      data:Object,
    },
    data: function () {
      return {  
      };
    },
    mounted() {
    },
    methods: {
      click(param){
        param;
        this.$emit('clicked', this.data)
      },
    },      
}
</script>

