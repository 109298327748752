<template>
  <div class="cell-container">
    <div  @click="click(data)">
        <!-- <div class="between margin">
          <div class="left">
            <div v-if="data.state == 0" class="badge border-stop margin-right" >신청전</div>
            <div v-if="data.state == 1" class="badge border-complete margin-right" >입금대기</div>
            <div v-if="data.state == 2" class="badge border-waiting margin-right" >승인대기</div>
            <div v-if="data.state == 3" class="badge border-ing margin-right" >배포중</div>

          </div>   
          <div>
            <div v-if="data.state == 0" @click.stop="onAddDist" class="badge back-dark margin-right" >신청하기</div>
            <div v-if="data.state == 1" @click.stop="onDeposit" class="badge back-blue margin-right" >입금확인요청</div>
            <div v-if="data.state == 2" @click.stop="onDeposit" class="badge back-orange margin-right" >입금내역</div>
          </div>       
        </div> -->

        <div style="display:flex; margin: 20px 0;">
          <!-- 대표이미지 -->
          <!-- <img v-show="data.url" style="margin-right: 5px" v-bind:src="data.url"> -->
          <!-- 타이틀 -->
          <div class="v-center">
            <div style="display: flex;">
              <div v-show="data.reward > 0" class="badge border-reward margin-right reward-container" >{{ data.reward }}P</div>
              <div class="post-text post-font-family">{{data.title}}</div>
            </div>
          </div>
        </div>

        <!-- 하단 -->
        <div class="between ">
          <div class="left cell-sub-color cell-sub-font">
            <div >배포량 {{data.amount}}</div>
            <div class="margin-side"> | </div>
            <div >전달 {{data.amount - data.remaining}}</div>
            <div class="margin-side"> | </div>
            <div >확인 {{data.dones}}</div>
          </div>          

          <div class="cell-sub-color cell-sub-font">{{timeString(data.created)}}</div>
        </div>

        <!-- 신고메뉴 -->
        <div v-if="data.is_monitor || data.is_report" class="admin-bar">
          <div v-if="data.is_monitor" class="admin-button admin-button-normal-color" @click.stop="onShow">{{hidden == 0 ? '숨기기' : '보이기'}}</div>
          <div v-if="!managed && data.is_report" class="admin-bar">
            <div class="admin-button admin-button-normal-color" @click.stop="manage('pass')">정상글</div>
            <div class="admin-button admin-button-dnager-color" @click.stop="manage('delete')">글삭제</div>
            <div>{{type}}</div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'
// import BoardTitle from './BoardTitle.vue';

export default {  
    components: { 
      // BoardTitle 
    },  
    props:{
      data:Object,
    },
    mounted() {
      this.hidden = this.data.hidden;
      this.managed = this.data.managed;
      this.type = Const.reportType[this.data.type];
    },
    data: function () {
      return {  
        //   name: value
        hidden: 0,
        managed: 0,
        type: '',
      };
    },
    methods: {
      onStop(){
        console.log('onStop')
      },
      onDeposit(){
        // 입금확인폼
        this.$emit('deposit', this.data)
      },
      onAddDist(){ // 배포량 추가
        console.log('onAddDist')
        this.$emit('dist', this.data)
      },
      onShow(){
        this.$emit('show', this.data.idx, this.hidden, this)
      },
      click:function(data){
          // console.log('postcell.click : ', data)    
          this.$emit('clicked', data)


          // const param = {
          //   data
          // }

          // const obj = {
          //   name: Const.component.PostForm,                
          //   param            
          // }          
          // common.popUp(obj);
      },
      manage(type){
        console.log('manage', type)
        this.$emit('manage', type, this.data, this)
      },
      timeString(t){
        return common.timeString(t);
      },
      test(){
        console.log('hello')
      }      
    },      
}

</script>

<style scoped>
@import '@/assets/common';

.reward-container{
  height: fit-content; 
  margin-top: auto; 
  margin-bottom: auto;
}

.admin-bar {
  display: flex;
  margin: 10px 0;
}

.margin {
  margin: 5px 0 5px 0 ;
}

img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.post-container {
  border-radius: 5px;
  padding: 10px;
  /* border: 2px solid #ccc; */
  /* background-color: #eee; */
  /* padding: 16px; */
}

.left {
  display: flex;
}

.post-container::after {
  content: "";
  clear: both;
  display: table;
}

/* .post-container img {
  float: left;
  margin-right: 20px;
  border-radius: 5%;
} */

.post-container span {
  font-size: 20px;
  /* margin-right: 15px; */
}


.post-container .hash {
  background: yellow;
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
}

.post-container .like {  
  font-size: 14px;
  float: left;
  color: darkgray;
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 4px;
}


</style>

