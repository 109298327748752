<template>
  <div v-if="is_show" class="side-menu " @click="close" style="z-index: 3;">
    <div class="menu">
      <div v-if="is_login == false" class="side-menu-item" @click="onLogin">로그인 / 가입</div>
      <div v-else>
        <div v-for="(item, index) in menus" v-bind:key="index" :class="index == selected_menu1" class="side-menu-item" @click.stop="item.onclick"><div v-html="item.html" /></div>
        
        <div v-if="is_login == false" class="side-menu-item" @click="onJoin">가입</div>

        <div v-if="config.logout" class="side-menu-item" @click="onLogout">로그아웃</div>

        <!-- <div v-for="(item, index) in admin" v-bind:key="index" :class="index == selected_menu1" class="side-menu-item" @click.stop="item.onclick"><div v-html="item.html" /></div> -->
        <!-- 나중에 가려줘야 한다. -->
        <!-- <div v-else class="side-menu-item" @click="onLogout">로그아웃</div> -->
      </div>
      <div class="side-menu-item" @click="onIntro">서비스 소개</div>
      <!-- <br>
      <div class="side-menu-item" @click="onEvent">친구추천이벤트</div> -->
      <div v-if="is_admin">
        <div v-for="(item, index) in admin" v-bind:key="index" :class="index == selected_menu1" class="side-menu-item" @click.stop="item.onclick">
          <div v-html="item.html" />
        </div>
      </div>

    </div>

    <!-- 메뉴2 -->
    <div v-if="is_show && menus2.length > 0" class="menu">
      <div v-for="(item, index) in menus2" v-bind:key="index" class="side-menu-item" @click.stop="onMenu(item)">{{item.title}} </div>
      <div class="tail"></div>
    </div>
  </div>
</template>

<script>
import common from '@/common'
import Const from '@/const'

export default {  
    props:{
      data:Object,
      id: String,
    },
    mounted() {
        console.log('data', this.data)
    },
    data: function () {
      return {
        is_admin: false,
        // is_temp: true, // 임시계정인가?
        is_login: false,
        selected_menu1: -1,
        is_show: false,
        menus: [
          {
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">home</span><div class="item-title">홈</div></div>',
            onclick: () => {
              console.log('홈')
              this.close();
            }
          },            
          {
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">bookmark</span><div class="item-title">저장됨</div></div>',
            onclick: () => {
              common.push({
                param: {type: 'like'},
                name: Const.component.SavedView,
              })              
              this.close();
            }
          },    
          // {
          //   html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">bookmark</span><div class="item-title">충전신청</div></div>',
          //   onclick: () => {
          //     common.push({
          //       name: Const.component.ChargeListView,
          //     })              

          //     this.close();
          //   }
          // },            
          {
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">savings</span><div class="item-title">적립내역</div></div>',
            onclick: () => {
              common.push({
                name: Const.component.PointLogListView,
              })              

              this.close();
            }
          },            
          {
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">money</span><div class="item-title">상품권교환</div></div>',
            onclick: () => {
              common.push({
                name: Const.component.ExchangeListView,
              })              

              this.close();
            }
          },            
          {
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">campaign</span><div class="item-title">발행하기</div></div>',
            onclick: () => {
              common.push({
                name: Const.component.MyPostsView,
              })              

              this.close();
            }
          },
          {
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">campaign</span><div class="item-title">충전하기</div></div>',
            onclick: () => {
              common.push({
                name: Const.component.ChargeListView,
              })              

              this.close();
            }
          },
          {
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">settings</span><div class="item-title">설정</div></div>',
            onclick: () => {
              common.push({
                name: Const.component.SettingView,
              })              

              this.close();
            }
          },            
          {
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">add_reaction</span><div class="item-title">에반젤리스트</div></div>',
            onclick: () => {
              common.push({
                name: Const.component.ChildListView,
              })              

              this.close();
            }
          },            
        ],
        admin: [       
          {              
            html: '<div class="item"><span :class="bookmark" class="material-symbols-outlined">article</span><div class="item-title">승인요청목록</div></div>',
            onclick: () => {
              common.push({
                name: Const.component.PaymentListView,
                // name: Const.component.AdminChargeListView,
              })              

              this.close();
            }
          },            
        ],
        menus2:[]
      };
    },
    methods: {
      onEvent(){
        const code = this.session.user.recommend_code;
        // console.log('this.session', this.session )

        const url = process.env.NODE_ENV == 'production' ? `https://abouting.co.kr/?/e1/${code}` : `http://localhost:8080/?/e1/${code}`
        const obj = {
            name: Const.component.QrcodeVue,
            param: {
              url,
              comment: `
                친구에게 어바우팅을 추천하고 <br>
                네이버페이 1000포인트를 받으세요.<br>
                이벤트기간 2023. 1. 14까지
              `
            }
        }
        common.popUp(obj)
      },
      onIntro(){
        // const obj = {
        //     name: Const.component.IntroView,
        //     param: {}
        // }
        // common.popUp(obj)
      },
      onJoin(){
        common.popUp({
          name: Const.component.JoinForm,
          param: {}
        })
      },
      onLogin(){
        common.popupLogin();

        // test code
        {
          // this.onLogout()
        }
      },
      onLogout(){

        // clear localStorage
        common.clearStorage();

        // api logout
        const cb = () => {
            location.reload();
        }
        this.logout(cb)
      },
      logout(cb){
        {
          const url = Const._api + 'logout';
          common.api_get(url, cb)
        }        
      },
      close: function(){
          this.is_show = false;
      },
      click:function(){
      },
    },  
}

</script>

<style>
@import '@/assets/common';
body {
  font-family: 'Noto Sans KR', sans-serif;
}

.tail {
  height: 100px;
}

.menu {
  border-left: solid 1px lightgray;
  padding: 20px 20px 20px 10px;
  height: calc(100vh - 40px);
}

.side-menu {
  position: fixed;
  top: 0;
  display: flex;
  height: 100vh;
  width: 100%;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  /* overflow-x: hidden; */
  overflow: auto;
  transition: 0.5s;
  /* padding-top: 20px; */
  text-align:center;
}


.side-menu a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.side-menu a:hover{
  color: #f1f1f1;
}

.side-menu .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

.item {
  display: flex;
  align-items: center;
}

.item-title {
  margin-left: 5px;
}

</style>