<template>
    <div style="display: flex; align-items: center;">
        <img src="/cherry.svg" :style="style" />
    </div>    
</template>

<script>
// import common from '@/common'
// import constVal from '@/const'

export default {  
    props:{
      size: String,
    },
    data() {
      return {
        style: {}
      };
    },
    mounted() {
        // console.log('mounted')
        this.style.height = this.size;
        this.style.width = this.size;
        // this.style.fill = 'blue';
    },
    methods: {
        onEnter(){
            console.log('onEnter')
            this.$emit('entered', this.value)            
        },
        onChange(){
            this.$emit('changed', this.value)            
        },
        click(param){
            param;
            this.$emit('clicked', this.data)
        },
    },      
}
</script>

<style scoped>
@import '@/assets/common';


</style>



