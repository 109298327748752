<template>
    <div id="container" class="border-cell">
        <div class="blank font-size14" v-html="text"></div>

        <div v-if="!hide" class="h-center" >
            <div class="text-button-color" style="margin-top: 20px" 
                @click="click">{{title ? title : '글쓰기'}}
            </div>
        </div>
    </div>
</template>

<style>
@import '@/assets/common';

</style>
<style scoped>

#container {
    background: white;
    padding: 30px;
    margin: 10px;
}

</style>

<script>
export default {  
    props:{
      text:String,
      hide:Boolean,
      title:String,
    },
    mounted() {
        // console.log('data', this.data)
    },
    data: function () {
      return {  
        //   name: value
        str: `같은 관심사를 가진 사람들과 \n익명으로 자유롭게\n 이야기를 나눠보세요`
      };
    },
    methods: {
      click(){
          this.$emit('clicked')
      },
    },      
}

</script>

