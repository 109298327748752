<template>  
  <div style="overflow: auto; height: 100vh;">
    <title-bar-ex ref="titlebar" :container="container" :right="{}"/>
    <div ref="scrollToMe" class=" background-color" v-on:scroll.passive="handleScroll" >
      <div class="cell-background-color ">
        <slideview ref="slideView"  />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import common from '@/common'
import Const from '@/const'
import TitleBarEx from './common/TitleBarEx.vue'
import slideview from '@/components/SlideView'
// import Navbar from '@/components/common/NavBar'
  // more module style...

  const str_reply = '댓글';
  const str_talk = '비밀댓글';

  const tabmenus = [str_reply, str_talk];

  export default {
    components: {
      TitleBarEx,
      slideview,
      // Navbar,
    },
    setup() {
      // console.log('setup');
      return {
        // modules: [Pagination]
      }
    },
    props: {
      param: Object,
      container: Object,
    },
    data() { 
      return  {     
        row: {},

        str_reply, 
        str_talk,
        tags: ['a', 'b'],
        tail_msg: {
          reply: Const.message.fetch,
          message: '',
        }, 
        reply_end: false, 
        show_swiper: true,
        show_member: false,
        idx: 0,
        last_idx: 0,
        board: '',
        title: '',
        content: '',       
        nick: '',
        likes: 0,
        persona: null,
        show_form: false,
        time_str: '',
        created: '',
        rooms: [],
        messages: [],
        base: Const._base,
        re: null,   // 포커스 받은 re_idx
        selectedTab : tabmenus[0],
        repls: [],
        replMap: {},
        nickMap: {},

        title_left : [
            {
              html: '<i class="fa fa-chevron-left padding-left10" style="padding-right:10px"></i>',
              onClick: () => {common.back();}
              // onClick: () => {this.click();}
            },
        ],
      }
    },
    created () {
      // console.log('created')
    },
    mounted() {   
      // console.log('PostView.mounted', this.param)
      console.log('PostView.mounted', this.param.idx, this.param.hash)

      this.$refs.titlebar.updateButtonState()
      this.$refs.titlebar.left = this.title_left;

      // this.$refs.navbarBottom.is_share = true;

      common.registEventHandler({
          onShow: this.onShow,
          onHide: this.onHide,
          onEvent: this.onEvent
      })

      if(!this.param.idx) return;

      this.row = this.param;

      const cb = (isLogin, user) => {
        Object.assign( this.user, user);
        
        const {idx, re_idx} = this.param;
        re_idx; // reply
        this.idx = idx;
        this.initView(this.idx);
      }
      common.checkSession(cb);
    },    
    computed:{
      isMine(){
        return this.persona && this.row.persona_idx == this.persona.idx
      },
      myRating(){
        if(this.row.rate == null) return '보통'
        
        return this.row.rate == 2 ? '호감' : '비호감'
      }      
    },
    methods:{  
      onFocusReInput(){
        console.log(this.session.user)
        if(!this.session.user) {
          common.popupLogin();
        }
      },
      timeString(t){
        return common.elapsedTime(t);
      },
      onTag(tag){
        // console.log('onTag', tag);

        // 게시판으로 이동해야 한다. {게시판, 태그보여주기, 태그 }
        common.push({
          name: Const.component.SavedView, // 'SavedView',
          param: {
            idx: this.row.board_idx,
            tag,
          },
        })
      },
      onRate(){
        this.popMember(this.row.persona_idx)
      },
      handleScroll(e){
        // // console.log('handleScroll');
        const {target} = e;
        const {clientHeight, scrollTop, scrollHeight} = target;

        // // console.log('clientHeight', clientHeight)
        // // console.log('scrollTop', scrollTop)
        // // console.log('scrollHeight', scrollHeight);        
        if((clientHeight + scrollTop + 10) >= scrollHeight){
          this.onScrollBottom();
        }
        else if(scrollTop == 0){
          this.onScrollTop();
        }
      },
      onScrollTop(){
        // console.log('onScrollTop');
        // this.getNewRepls();
      },
      onScrollBottom(){
        console.log('onScrollBottom');
        // const tabmenus = [str_reply, str_talk];
        switch (this.selectedTab) {
          case str_reply:
            {
              if(!this.reply_end){
                const last_idx = this.repls.length > 0 ? this.repls[0].idx : 0
                this.requestReplies(last_idx);
              }
            }            
            break;
          case str_talk:
            // todo : 
            break;
          default:
            break;
        }
      },
      onEvent(name, param){
        // // console.log('onEvent', name, param, this.idx);

        switch (name) {
          case Const.notify.post_update:
            if(this.idx == param.post_idx){
              this.requestBody();
            }            
            break;
          default:
            break;
        }
      },
      onHide(){
        // // console.log('PostView.onHide')

        // this.show_swiper = false;
      },
      onShow(){
        // // console.log('PostView.onShow')

        this.show_swiper = true;
      },      
      initView(idx){        
        common.checkSession((isLogin, user) => {

          if(isLogin){
            this.didLogin(user)
          }else{
            this.notLogin();
          }

          this.idx = idx;

          // this.$refs.tabmenu.menu(tabmenus);

          this.requestBody();
        });
      },
      requestBody(){
        console.log('requestBody', this.row)
        const url = `${Const._api}adv/${this.row.idx}/${this.row.hash}`
        // const url = `${host}/api/my/ad/${idx}`
        console.log(url)
        axios.defaults.withCredentials = true;
        axios.get(url).then(response => {
            const data = response.data;
            console.log(data)
            const {error, row, msg} = data;

            if(error > 0){
                console.log(msg)
                return;
            } 

            row.logined = row.needLogin != 'y';
            console.log('row>', row)

            this.row = row;
            this.$refs.slideView.update(this.row)
            this.$refs.slideView.start();
        })
        .catch(error => {
            console.error(error);
        });
      },
      showModal: function(header, body, callback){
        const param = {
            header,
            body,
            callback
        }
        common.popupModal(param);
      },
      setReplyInfo(){
        // 댓글에 대한 정보 처리
        this.repls.forEach(element => {
          this.replMap[element.idx] = element;
          element.persona = this.user.persona;
          element.url = Const._base + element.url;

          if(element.nick) this.nickMap[element.persona_idx] = element.nick;

          if(element.persona_idx == this.row.persona_idx) element.writer = true;
        });

        this.repls.forEach(element => {
          if(element.p_idx){
            if(this.replMap[element.p_idx]){
              if(element.p_idx) element.mention = {...this.replMap[element.p_idx]};
            }
          }
        });

        if(this.user && this.user.persona){
          this.repls.forEach(element => { 
            if(element.persona_idx === this.user.persona.idx) element.me = true;              
          });
        }
      },
      getNewRepls(){
        let after = 0;
        if(this.repls.length)
        {
          const elm = this.repls[0];
          after = elm.idx;
        }

        const url = Const._api + `reply/new/${this.idx}/${after}`;
        common.api_get(
          url, res => {
            const {error, msg} = res;
            if(error > 0){
              this.showModal(msg);
              return;
            }
      
            // // console.log(res.data);
            const {data} = res;
            
            if(data.rows && data.rows.length > 0)
            {
              this.repls = data.rows;
            }
            else {
              common.toast(Const.message.no_new)
              return
            }

            if(data.rows) this.setReplyInfo();

            console.log('this.repls', this.repls)

            // if(data.rows && this.repls){
            //   this.repls.forEach(element => {
            //     this.replMap[element.idx] = element;
            //     element.persona = this.user.persona;
            //     element.url = Const._base + element.url;

            //     if(element.p_idx) element.mention = this.replMap[element.p_idx];

            //     if(element.nick) this.nickMap[element.persona_idx] = element.nick;
            //   });
            // }

            // if(this.user && this.user.persona){
            //   this.repls.forEach(element => { 
            //     if(element.persona_idx === this.user.persona.idx) element.me = true;              
            //   });
            // }
          }
        );           
      },
      requestReplies(start=0){

        if(start == 0) this.repls = [];

        if(this.repls.length)
        {
          const elm = this.repls[this.repls.length - 1];
          start = elm.idx;
        }

        const url = Const._api + `reply/list/${this.idx}/${start}`;
        common.api_get(url, res => {
          const {error, msg} = res.data;
          if(error > 0){
            this.showModal(msg);
            return;
          }
    
          const {rows, end} = res.data;
          
          this.tail_msg.reply = end ? '' : this.tail_msg.reply;

          if(rows && rows.length > 0)
          {
            this.repls = this.repls.concat(rows);
          }
          else // 더 이상 묻지마
          {
            // // console.log('업다')
            this.reply_end = end;
          }

          if(rows.length > 0) this.setReplyInfo();
          // console.log('this.repls', this.repls)

          this.repls.forEach(element => {
            console.log('element.mention>', element.mention)
          });


          // if(rows && this.repls){

          //   this.repls.forEach(element => {
          //     this.replMap[element.idx] = element;
          //     element.persona = this.user.persona;
          //     element.url = Const._base + element.url;

          //     if(element.p_idx) element.mention = this.replMap[element.p_idx];

          //     if(element.nick) this.nickMap[element.persona_idx] = element.nick;
          //   });
          // }

          // if(this.user && this.user.persona){
          //   this.repls.forEach(element => { 
          //     if(element.persona_idx === this.user.persona.idx) element.me = true;              
          //   });
          // }
        });       
      },
      notLogin(){
        // common.popupLogin();
      },
      didLogin:function(user){
        console.log('didLogin')
        Object.assign( this.user, user);
        this.$refs.titlebar.didLogin();

        // this.$refs.navbarBottom.is_write = true;
        // this.$refs.navbarBottom.is_menu = false;
        // this.$refs.navbarBottom.is_bookmark = true;
      },
      onMemberView(tag, param){
        param
        // // console.log('onMemberView', tag, param)

        switch (tag) {
          case 'back':
            this.show_member = false;
            break;
        
          default:
            break;
        }
      },
      onReInput(tag, param){
        // // console.log('onReInput', tag, param);

        switch (tag) {
          case 'login':
            // 로컬 데이터로 로그인을 시도해야 한다.
            common.tryLogin();
            break;
          case 'done':
            this.getNewRepls();
            break;        
          case 'error':
            common.toast(param);
            break;        
          default:
            break;
        }
      },
      onClose(){
        this.show_form = false;
      },
      onDone: function(){
          // // console.log('onDone');
          this.show_form = false;
          this.request();
      },
      onImageClick(url){
        // // console.log("onImageClick", url);
        common.showImage(url)
      },
      onReplyCell(tag, param){
        // // console.log('onReplyCell', tag, param);

        switch (tag) {
          case 'image':
            // // console.log(param.url);
            common.showImage(param.url)
            break;
          case 'menu':
            this.onReplyMenu(param);
            break;
          case 'like':
            this.onReplyLike(param);
            break;
          case 'reply':
            this.onReReply(param);
            break;        
          default:
            break;
        }
      },
      onTabMenu: function(menu){
        this.selectedTab = menu;
        // // console.log('index.selectedTab', menu);

        switch (menu) {
          case str_reply:
            break;
          case str_talk:
            if(!this.session.user){
              common.popupLogin();
            }
            else{
              this.scrollToElement()
            }
            break;
          default:
            break;
        }

        if(menu == this.around_board || this.around_post) // 위치 정보 없으면 가져오기 시도
        {
          if(this.$refs.locationbar.address == null){
            this.$refs.locationbar.search();
          }
        }
      },
      onNick(){
        this.popMember(this.row.persona_idx);
      },
      onTitle(){
        // console.log('onTitle')

        common.push({
          name: Const.component.SavedView, // 'SavedView',
          param: {idx: this.row.board_idx}
        })
      },
      onLeft(){
        common.back();
      },
      delPost(idx){
        const url = Const._api + `post/del/${idx}`;
        common.api_get(url, res => {
          const {error, msg} = res;
          if(error > 0){
            this.showModal(msg);
            return;
          }
          
          // const {data} = res;
          // console.log('deleted post', data);
          common.back();
          this.onDeleted(idx);
        });                
      },
      onDeleted(idx){
        idx;
        // console.log('onDeleted', idx)
        common.notifyEvent(Const.notify.post_del, {
          board_idx: this.row.board_idx,
          post_idx: idx,
        })
      },
      onHome(){
        this.$emit('event', 'home');
      },
      reportReply(idx, type){
        // console.log('reportReply', idx, type);

        const params = {idx, type};
        const url = Const._api + "/report_re";

        common.api_post(
          url, params, () => {
            const msg = '신고완료'
            common.toast(msg);
          },
          // , this.$refs.modal
          this.modalErrorMessage
        )      

      },
      reportPost(post_idx, type){
        const params = {post_idx, type};
        const url = Const._api + "/report";

        common.api_post(
          url, params, () => {
            const msg = '신고완료'
            common.toast(msg);
          },
          // , this.$refs.modal
          this.modalErrorMessage
        )      
      },
      share(){
        // const param = {
        //   url: `${window.origin}/?/ad/${this.row.idx}/${this.row.hash}`
        // } ;

        // common.popUp({
        //   name: Const.component.QrcodeVue,
        //   param
        // })
        const {idx, hash} = this.row;
        common.popupShare(idx, hash);
      },
      modalErrorMessage(msg){
        this.showModal('Error', msg)
      },
      popMember(idx){
        // console.log('popMember', idx);
        common.push({
          name: Const.component.MemberView, // 'MemberView',
          param: {idx},
        })
      },
      onReplyMenu(item){
        if(this.user == null){
          common.popupLogin();
          return;
        }

        console.log('onReplyMenu', item);
        const {persona_idx} = item;

        if(this.persona && persona_idx == this.persona.idx) // 자기껏
        {
          const arr = [
            // {
            //   title: '수정',
            //   cb: () => {
            //     // console.log('댓글수정', item)
            //   }
            // },
            {
              title: '삭제',
              cb: () => {
                // console.log('삭제', item)
                const params = {
                  post_idx: this.idx,
                  no: item.idx
                };
                const url = Const._api + "reply/del";

                common.api_post(
                  url, params, (data) => {
                    const {error, msg} = data;
                    msg
                    if(error == 0){
                      this.repls.splice(item.index, 1);
                    }
                    else if(error == 100)
                    {
                      common.tryLogin();
                    }
                    else{
                      // console.log('error', msg)
                    }
                  }
                  , this.$refs.modal                
                )      
              }
            },
          ]            
          common.showMenu(arr)            
        }else if(this.persona) // 타인의 댓글
        {            
            // ['신고', '이 회원글 안보기','작성자 정보'];
            // todo : 구현
            const arr = [
              {
                title: '신고',
                cb: () => {
                  common.showReportType(
                    (key) => {
                      this.reportReply(item.idx, key)
                    }                
                  )
                }
              },
              {
                title: '가리기',
                cb: () => {
                  // console.log('가리기', item)
                }
              },
              {
                title: '회원정보',
                cb: () => {
                  // console.log('회원정보', item)
                  this.popMember(item.persona_idx);
                }
              },
            ]            
            common.showMenu(arr)
        }
        else // 비로그인
        {
          common.popupLogin();          
        }
      },
      onClick(item){
        item
      },
      onSearch:function(data){
        data;
        // console.log('onSearch', data);
      },
      onReport(data){
        data;
        // console.log('onReport', data);
      },
      onLike(isLike){
        // console.log('onLike', isLike)

        if(this.user == null){
          common.popupLogin();
          return;
        }

        {
          const params = {
            post_idx: this.idx,
            isLike,
          };
          const url = Const._api + "like/add";          
          common.api_post(
            url, params, (data) => {
              // console.log('like', data);
              const {liked, error} = data;          
              if(error == 100)
              {
                common.tryLogin();
              }
              else {
                if(liked == 1){
                  this.row.likes += 1;
                  this.row.liked = 1                  
                }else{
                  this.row.likes -= 1;
                  this.row.liked = 0
                }              
              }
            }
            , this.$refs.modal
          )          
        }
      },
      onReplyLike(data){
        data
        // console.log('onReplyLike', data);
      },
      onReReply(item){
        // console.log('onReReply', item);

        if(this.user == null){
          common.popupLogin();
          return;
        }

        const params = {
          post_idx: this.idx,
          idx: 0,
          p_idx: item.idx,
        }
        this.$refs.reInput.fill(params)
        this.$refs.reInput.setMention(item);
      },
      onError(error, param){
        // console.log('onError', error, param);

        // todo : notlogin 처리 / 리책토링

        common.toast(param)
      },  
      scrollToElement() {
        const el = this.$refs.scrollToMe;

        const f = () => {
          if (el) {
            el.scrollTop = el.scrollHeight;
          }
        }
        setTimeout(f, 1);
      },      
      onNoRoom(){
        // console.log('onNoRoom');

        this.getTalkRoomId(
          (idx) => {
            // console.log('idx', idx);
            this.room_idx = idx;
            this.$refs.messageInput.room_idx = this.room_idx;
            this.$refs.messageInput.send();
          }
        )
      },
      didSend(){
        // const url = Const._api + `talk/room/old/${this.room_idx}/${this.last_idx}`;

        const last_idx = this.messages.length > 0 ? this.messages[this.messages.length - 1].idx : 0;
        const url = Const._api + `talk/room/new/${last_idx}`;

        common.api_get(url, res => {
          const {error, msg, joined} = res.data;
          if(error > 0){
            // console.log('msg', msg);
            this.showModal('오류', msg);
            return;
          }

          if(joined){
            const {persona_idx, board_name} = joined;
            this.title = `${persona_idx}@${board_name}`
          }

          if(res.data.rows.length > 0) this.last_idx = res.data.rows[0].idx
          // console.log('this.last_idx', this.last_idx);

          // this.rows.concat(res.data.rows.reverse());
          this.messages = this.messages.concat(res.data.rows.reverse());
          
          this.scrollToElement();
        });
      },   
    },    
  }
</script>

<style >
@import '@/assets/common';
</style>

<style scoped>

.swiper {
  z-index: 0;
}

.tail {
  height: 240px;
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */

    margin-bottom: 40px;
}

.row {
    font-size: 20px;
    background: peachpuff;
}

.left {
  display: flex;
}

#repls {
  margin-bottom: 30px;
}

</style>

