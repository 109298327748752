<template>
  <div>
    <div class=" background-color" v-on:scroll.passive="handleScroll" >
      <!-- top -->

      <title-bar-ex ref="titlebar" :container="container" :right="right" :onTitle="onTitle"/>

      <!-- 나의 정보가 아닌 경우 -->
      <div v-if="idx > 0">
        <RatingPersona ref="ratingPersona" />
        <div class="text-button bold" style="margin: 10px auto; font-size: 14px;" @click="onBlock" :class="info.block_idx > 0 ? 'text-button-color': 'color-danger'" >
          {{info.block_idx > 0 ? '차단해제' : '차단하기'}}
        </div>
      </div>

      <!-- middle -->
      <div class="" id="contents" >
        <TabMenu class="tabmenu sticky-top" :data="[str_post, str_reply]" v-on:clicked="onTabMenu"></TabMenu>

        <!-- 포스트 -->
        <div v-show="selectedTab == str_post" style="display: flex; flex-direction: column;">
          <div class="" >
            <postcell v-for="(item, index) in posts" v-bind:key="index" :data="item" v-on:clicked="onPost"></postcell>
          </div>    
        </div>
        <!-- 댓글 -->
        <div v-show="selectedTab == str_reply">
          <div class="margin-side">
            <div v-if="idx > 0">
              <ReplyCell v-for="(item, index) in replies" v-bind:key="index" :data="item" v-on:clicked="onReplyCell" />
            </div>
            <div v-else>
              <postcell v-for="(item, index) in replies" v-bind:key="index" :data="item" v-on:clicked="onReplyCell" />
            </div>
          </div>    
        </div>   
      </div>    
      
      <!-- bottom -->
      <div class="bottom" onclick="onTop()"></div>
    </div>
  </div>
</template>

<script>
  import common from '@/common'
  import Const from '@/const'
  import TabMenu from '@/components/common/TabMenu.vue'
  import postcell from '@/components/common/PostCell.vue'

  import 'swiper/css'
  import 'swiper/css/pagination'
  // import TitleBar from '@/components/common/TitleBar.vue'
  // import ModalDialog from '@/components/ModalDialog.vue'
  import ReplyCell from '@/components/common/ReplyCell.vue'
  import RatingPersona from './common/RatingPersona.vue'
  import TitleBarEx from './common/TitleBarEx.vue'
  // more module style...

  const str_post = '포스트';
  const str_reply = '댓글';

  export default {
    components: {
      TabMenu,
      postcell,
      // TitleBar,
      ReplyCell,
      RatingPersona,
      TitleBarEx,
    },
    setup() {
      console.log('board setup');
      return {
      }
    },
    props: {
      param: Object,
      container: Object,
    },
    data(){ 
      return {         
        title: '',
        is_me: false,   
        show_rating: false, // 평점 영역 보여주기
        data: {block_idx: 0},
        rate: 0,
        rating_count: 0,
        avr: 0, // 평점 평균
        chnaged: false, // 평점 변경
        info: {name:'', board_name:''},
        str_post,
        str_reply,
        me: null,
        idx: 0,
        isLike: false,
        persona: null,
        selectedTab: str_post,
        posts: [],        
        replies: [],
        show_form: false,   // 작성폼
        nickname: '',
        end: {
          post: false,
          reply: false,
        },
      }
    },
    created: function () {
        // this.idx = common.param("idx");
    },
    computed: {
      right(){
        if(this.idx > 0) return {
          html: '<span class="material-symbols-outlined">chat_bubble</span>',
          onClick: this.onMessage,
        }
        else return {

        }
      },
    },
    mounted: function() {
      console.log('Memberview.mountend', this.param)

      this.idx = this.param.idx

      {
          const comp = {
              onEvent: this.onEvent,
          }
          common.registEventHandler(comp)
      }

      this.initView()
    },    
    methods:{   
      initView(){
        common.checkSession((isLogin, user) => {
          if(isLogin){
            this.didLogin(user)
          }else{
            this.notLogin();
          }
          // this.request(0);
          if(this.idx == 0)
          {
            this.$refs.titlebar.title = '나의 정보'
          }        

          this.requestPersona();
        })      
      },
      requestPosts(start = 0){
        console.log('requestPosts')
        let url = ''
        if(this.idx > 0){
          url = Const._api + `post/persona/${this.idx}/${start}` 
        }
        else{
          url = Const._api + `post/my/${start}`;

        }

        common.api_get(
          url, res => {
            const {data} = res;

            this.data = data;
            // this.$refs.snackbar.hide();

            const {rows} = data;

            if(rows.length == 0){
                // todo : 더 이상 레코드가 없는 경우 처리
                
                const msg = '모두 읽었습니다.'
                common.toast(msg);
                this.end.post = true;
                return ;
            }

            rows.forEach(element => {
              element.name = '';
            });
            this.posts = this.posts.concat(rows);  
          }
          ,this.modalErrorMessage
        );
      },
      onBlock(){
        if(this.info.block_idx > 0){
          // 해제
          common.unBlockPersona(
            this.info.block_idx, 
            () => {
              this.info.block_idx = 0;
            }
          );
        }
        else{
          // 차단
          common.blockPersona(
            this.idx,
            (idx) => {
              console.log('idx', idx);
              this.info.block_idx = idx;

              common.notifyEvent(Const.notify.block_persona, {idx: this.idx})
            }
          )
        }
      },
      handleScroll(e){
        const {target} = e;
        const {clientHeight, scrollTop, scrollHeight} = target;

        if((clientHeight + scrollTop + 1) >= scrollHeight){
          this.onScrollBottom();
        }
        else if(scrollTop == 0){
          console.log('top');
          this.onScrollTop();
        }
      },      
      didLogin(user){
        console.log('didLogin', user);
        // this.$refs.titlebar.title = user.uid;
        this.$refs.titlebar.didLogin(false)
      },      
      notLogin: function(){
        console.log('로그인상태가 아님');
      },
      reset(){
        this.data = {};
      },
      requestPersona(){
        const url = Const._api + `persona/row/${this.idx}`;

        common.api_get(
          url, 
          res => {
            const {data} = res;
            const { row } = data;

            console.log('requestPersona.data', data)

            this.requestPosts();

            if(row){
              this.info = row;
              {
                // todo : rating
                const param = {
                  persona_idx: this.idx,
                  idx: row.rate_idx,
                  rate: row.rate !=undefined ? row.rate : 1, // 보통(1)
                  count: row.likes
                }
                this.$refs.ratingPersona.update(param)
              }

              const {rating_count, rating_total, rate} = row;
              if(rating_count > 0)
              {
                this.avr = rating_total / rating_count;
                this.rating_count = rating_count;
              }

              if(rate) this.rate = rate;

              if(row.name){
                this.nickname = row.name;

                // this.$refs.titlebar.title = row.name
                this.setTitle(row.name)
              }
              else{
                this.setTitle('(회원 메모하기)')
              }
              
              this.$refs.titlebar.title_proc = this.popupNickDialog;

              const name = row.board_name.split('@').length > 0 ? row.board_name.split('@')[0] : row.board_name;

              const arr = row.board_name.split('>')
              if(arr.length > 1){
                this.$refs.titlebar.subTitle = `
                  <div style="display:flex;">
                    <div style="margin-right: 2px;">${name}</div>
                    <i class="fa fa-map-marker font-size12 margin-v-auto" style="margin-right: 2px;" aria-hidden="true" ></i>
                    <div>${arr[2]}</div>
                  </div>
                  `
              }
              // this.$refs.titlebar.subTitle = row.board_name;
            }
          }
          ,(error, msg) => {
            if(error == 10){ // 나다.
              this.idx = 0;
              this.initView();
            }
            else if(error > 0){
              const header = '오류'
              const param = {
                header,
                body: msg,
                callback: () => {
                  common.back();
                }
              }
              common.popupModal(param);        
            }
          }
          ,() => {
            common.popupLogin();
          }
        );
      },
      request(start = 0){
        let url = ''
        if(this.idx > 0){
          this.requestPersona();
          url = Const._api + `post/persona/${this.idx}/${start}` 
        }
        else{
          url = Const._api + `post/my/${start}`;

        }

        common.api_get(
          url, res => {
            const {data} = res;

            this.data = data;

            const {rows} = data;

            if(rows.length == 0){
                // todo : 더 이상 레코드가 없는 경우 처리
                
                const msg = '모두 읽었습니다.'
                common.toast(msg);
                this.end.post = true;
                return ;
            }

            rows.forEach(element => {
              element.name = '';
            });
            this.posts = this.posts.concat(rows);  
          }
          ,this.modalErrorMessage
        );
      },
      modalErrorMessage(body, callback){
        const header = '오류'
        const param = {
            header,
            body,
            callback
        }
        common.popupModal(param);
      },
      requestReply(start=0){
          const url = this.idx > 0 ? 
          Const._api + `reply/persona/${this.idx}/${start}`:
          Const._api + `reply/my/${start}`;

          common.api_get(
            url, res => {
              const {data} = res;

              this.data = data;
              // this.$refs.snackbar.hide();

              const {rows} = data;

              if(rows.length == 0){
                const msg = '모두 읽었습니다.'
                common.toast(msg);

                this.end.reply = true;
                return;
              }

              if(this.idx > 0){
                rows.forEach(element => {
                  const bottomLeft = {
                    title: '',                    
                  }
                  const bottomRight = {
                    title: '',                    
                  }
                  element.bottomLeft = bottomLeft;
                  element.bottomRight = bottomRight;
                });
              }

              this.replies = this.replies.concat(rows);  
            }
            , this.modalErrorMessage
          );
      },
      onEvent(name, param){
        console.log('onEvent', name, param)

        switch (name) {
          case Const.notify.login:
            this.initView();
            // this.request(0)            
            break;
          case Const.notify.nickname:          
            break;
        
          default:
            break;
        }
      },
      onStar(rate){
        console.log('onStar', rate)
        this.rate = rate;
        this.chnaged = true;
      },
      onDoneRate(){ // 평점 완료
        const url = Const._api + "my/rate";
        const body = {
          persona_idx: this.idx,
          rate: this.rate
        }
        common.api_post(
          url, body, (data) => {
            console.log(data);
            const msg = '등록완료'
            common.toast(msg);

          }
          ,this.modalErrorMessage
        )      
      },
      onUndoRate(){ // 평점 취소
        // 이미 내린 평점을 취소하는게 필요할까? 
      },
      onLeft(){
        // window.history.back();
        this.$emit('event', 'back')
      },
      onRight(){

      },
      onScrollTop(){
        console.log('onScrollTop');
      },
      onScrollBottom: function(){
          switch (this.selectedTab) {
            case '포스트':
              {
                if(this.end.post)
                {
                  const msg = '모두 읽었습니다.'
                  common.toast(msg);
                  return;    // 더 이상 레코드 없으면...
                } 

                const length = this.posts.length;
                if(length == 0) return;

                const lastIdx = this.posts[length-1].idx;

                this.request(lastIdx);
              }
              break;
            case '댓글':
              {
                if(this.end.reply)
                {
                  const msg = '모두 읽었습니다.'
                  common.toast(msg);
                  return;    // 더 이상 레코드 없으면...
                } 

                const length = this.replies.length;
                if(length == 0) return;

                const lastIdx = this.replies[length-1].idx;

                this.requestReply(lastIdx);
              }
              break;
            default:
              break;
          }
      },
      onLike(){
        console.log('onLike', this.isLike);

        {
            const url = Const._api + "board/like";
            const param = {
              board_idx: this.idx,
              like: this.isLike != 1,
            }
            common.api_post(url, param, data => {
                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }
                this.$emit('done');
                this.isLike = this.isLike === 0 ? 1 : 0;
            })
        }          
      },
      onMessage(){

      },
      onTabMenu: function(menu){
          this.selectedTab = menu;

          switch (menu) {
            case '댓글':
              if(this.replies.length > 0) return;
              this.requestReply();
              break;
          
            default:
              break;
          }
      },
      onTitle(){
        this.popupNickDialog();
      },
      popupNickDialog(){
        // this.show_input = true;
        // this.$refs.inputForm.show();

        const param = {
          title:'', 
          comment: Const.message.memo, 
          placeholder:'10자이내로 입력하세요.', 
          text:this.nickname,
          cb: (name) => {
            const url = Const._api + "my/nick/set";

            const params = {
              persona_idx: this.idx,
              name
            }
            common.api_post(url, params, data => {
                console.log('data', data)

                const {error, msg} = data; 
                if(error == 100)    // not login
                {
                    common.tryLogin();
                    return;
                }
                else if(error > 0){
                    console.log('error', error);
                    this.$emit('error', error, msg);      
                    return;
                }

                this.setTitle(name);

                common.notifyEvent(Const.notify.nickname, {idx: this.idx, name})
            })
          }
        }
        const obj = {
            name: 'InputForm',
            param,
        }
        common.popUp(obj);
      },
      setTitle(title){
        this.$refs.titlebar.title = `<div class="font-size14 text-button-color">${title}</div>`
      },
      onMenu(){ // 본문메뉴
        // ['대화하기', '삭제', '닉네임 설정']
        const arr = [
          {
            title: '대화하기',
            cb: () => {
              this.onMessage();
            }
          },
          {
            title: '닉네임 설정',
            cb: this.popupNickDialog
          },
        ]
        common.showMenu(arr)
      },
      onReplyCell(data, param){
        if(this.idx > 0){
          switch (data) {
          case 'body':
            common.push({
              name: Const.component.PostView,
              param: {idx: param.post_idx},
            })
            break;
          case 'menu':
            console.log('menu', param)
            break;
          default:
            break;
          }
        }
        else{
          common.push({
            name: Const.component.PostView,
            param: {idx : data.post_idx}
          })
        }
      },
      onPost: function(data){
          common.push({
            name: Const.component.PostView,
            param: { idx: data.idx }
          })
      },
      onNew: function(){
          console.log('onNew');
          
          if(this.me)
          {
            this.show_form = true;
          }
          else
          {
            console.log('로그인상태가 아님')
            // todo : 로그인창
          }
      },
      onSearch:function(data){
          console.log('onSearch', data);
          // todo : 검색
      },
      onClose: function(){
          this.show_form = false;
      },
      onDone: function(){
          console.log('onDone');
          this.show_form = false;
          this.posts = [];
          this.request(0);
      },
      onError: function(error, param){
        console.log('onError', error, param);

        // todo : notlogin 처리 / 리책토링

        this.$refs.snackbar.show(param);
      }
    },
  }
</script>
<style >
@import '@/assets/common';
</style>
<style scoped>

.rating-margin {
  margin: 10px 0 
}

.center-align {
  display: flex; 
  justify-content: center;
}

.star-checked {
  color: orange;
}
.star {
  color: gray;
}

.bottom {
    width: 100%;
    height: 50px;
    /* background: blue; */
}

.row {
    font-size: 20px;
    background: peachpuff;
}

</style>>

