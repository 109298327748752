<template>
    <div v-if="is_show" class = "cxtmenu-container" @click="close">
        <div class="ctx-menus">
            <!-- <div class="title">title</div> -->
            <div v-if="title" class="title">{{title}}</div>
            <div v-for="(item, index) in menus" v-bind:key="index" class="menu" @click.stop="click(item)" :style="item.style">{{item.title}}</div>
            <div @click="close" class="menu">닫기</div>
        </div>
    </div>
</template>

<script>
export default {  
props:{
      data:Object
    },
    mounted() {
    },
    data: function () {
      return {  
        title: '',
        is_show: false,
        menus: [],
        param: null,
      };
    },
    methods: {
        showEx(obj){
            const {title, menus} = obj;
            this.title = title;

            this.show(menus)
        },
        show(menus){
            this.menus = menus;
            this.is_show = true;

            // console.log('this.menus', this.menus);
        }, 
        click:function(item){
            // console.log('click')
            this.close();
            item.cb();

            // console.log('this.is_show', this.is_show);
        },
        close:function(){
            // console.log('close')
            this.title = '';
            this.menus = [];
            this.is_show = false
        },
    },      
}

</script>

<style scoped>
.cxtmenu-container {
    width: 100%;
    height: 100%;
    position:fixed;
    bottom: 0;
    left: 0;
    background-color: rgba(0,0,0,0.3); /* Black w/ opacity */
    display: flex;
    justify-content: center;    
}

.ctx-menus {
    width: 80%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
    border-radius: 5px;
}

.ctx-menus .title {
    font-size: 20px;
    background: white;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 5px;
    height: unset;
}

.ctx-menus .menu {
    background: white;
    display: flex;
    justify-content: center;
    padding-top: 15px;
    padding-bottom: 5px;
    height: unset;
}
</style>

